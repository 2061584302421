<template>
  <div class="agus3-bottom">
    <div class="mb-6" v-for="(block, ind) in blocks" :key="ind">
      <div class="agus3-block__row">
        <div
          class="agus3-block__item"
          v-for="(item, iInd) in block.blocks"
          :key="iInd"
        >
          <div class="agus3-block__item-content">
            <div class="agus3-block__item-title" v-html="item.title"></div>
            <input
              type="checkbox"
              :id="`agus3_opener_${ind}_${iInd}`"
              v-if="item && item.tip"
            />
            <div class="agus3-block__item-tip" v-if="item && item.tip">
              <img :src="item.tip" alt="" />
            </div>
            <label
              :for="`agus3_opener_${ind}_${iInd}`"
              v-if="item && item.tip"
              class="agus3-block__item-button button button_small button_empty-pink d-inline-flex ml-auto"
            >
              <span class="open">Смотреть</span>
              <span class="close">Cкрыть</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 5.5L8.5 10.5L3.5 5.5"
                  stroke="#830051"
                  stroke-linecap="square"
                />
              </svg>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AGUSDropdownThree",
  computed: {
    blocks() {
      return [
        {
          blocks: [
            {
              title: "Алгоритм ведения пациента с атипичным гемолитико-уремическим синдромом",
              tip: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/tip3.png",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.agus3-bottom {
  margin-top: 32px;
  &__title {
    padding: 8px 16px;
    background-color: #8a7098;
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.agus3-block {
  &__row {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 32px;

    @media screen and (max-width: 767px) {
      padding: 16px 0 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 72px;

    &-arrow {
      width: fit-content;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-content {
      position: relative;
      border: 1px solid #fff;
      padding: 24px;
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
    }

    &-title {
      font-weight: 500;
      max-width: 615px;
      @media screen and (max-width: 1199px) {
        max-width: 380px;
      }
    }

    &-tip {
      max-height: 0;
      transition: 0.3s;
      overflow: hidden;
    }

    & input {
      display: none;
    }

    & input {
      &:checked {
        & ~ .agus3-block {
          &__item {
            &-tip {
              margin-top: 16px;
              max-height: 1500px;
              margin-bottom: 40px;
              @media screen and (max-width: 767px) {
                margin-bottom: 0;
              }
            }
            &-button {
              margin-top: 16px;
              & .open {
                display: none;
              }
              & .close {
                display: block;
              }
              & svg {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
    }

    &-button {
      margin-top: auto;
      position: absolute;
      right: 24px;
      bottom: 20px;
      & .open {
        display: block;
      }
      & .close {
        display: none;
      }
      & svg {
        transform: rotate(0deg);
        transition: 0.3s;
        margin-left: 4px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 16px;
        position: static;
      }
    }

    &:first-child {
      .agus3-block {
        &__item {
          &-content {
            border-color: #b19fba;
          }
        }
      }
    }

    &:last-child {
      .agus3-block {
        &__item {
          &-content {
            border-color: #d2d2d2;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.agus3-block__item {
  & sup {
    color: inherit !important;
  }
}
</style>
