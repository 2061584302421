<template>
  <div class="nf-tabs">
    <div class="nf-tabs__container">
      <div class="nf-tabs__header">
        <div class="nf-tabs__header-container">
          <div class="nf-tabs__header-svg">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="56" height="56" rx="28" fill="#F4EFF4" />
              <g clip-path="url(#clip0_7095_7684)">
                <path
                  d="M30.364 23.2426L23.864 29.7426C23.4661 30.1405 23.2426 30.68 23.2426 31.2426C23.2426 31.8052 23.4661 32.3448 23.864 32.7426C24.2618 33.1405 24.8014 33.364 25.364 33.364C25.9266 33.364 26.4661 33.1405 26.864 32.7426L33.364 26.2426C34.1596 25.447 34.6066 24.3679 34.6066 23.2426C34.6066 22.1174 34.1596 21.0383 33.364 20.2426C32.5683 19.447 31.4892 19 30.364 19C29.2387 19 28.1596 19.447 27.364 20.2426L20.864 26.7426C19.6705 27.9361 19 29.5548 19 31.2426C19 32.9305 19.6705 34.5492 20.864 35.7426C22.0574 36.9361 23.6761 37.6066 25.364 37.6066C27.0518 37.6066 28.6705 36.9361 29.864 35.7426L36.364 29.2426"
                  stroke="#A04B8E"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7095_7684">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(16 16)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="nf-tabs__header-text">
            Пациентка Е., 2009 г.р. (13 лет)
          </div>
        </div>
      </div>
      <div class="nf-tabs__tabs-content">
        <div class="nf-tabs__tabs-list">
          <div
            class="nf-tabs__tabs-btn"
            @click="changeTab(0)"
            :class="{ active: activeTab === 0 }"
          >
            Анамнез жизни
          </div>
          <div
            class="nf-tabs__tabs-btn"
            @click="changeTab(1)"
            :class="{ active: activeTab === 1 }"
          >
            Семейный анамнез
          </div>
          <div
            class="nf-tabs__tabs-btn"
            @click="changeTab(2)"
            :class="{ active: activeTab === 2 }"
          >
            Жалобы на момент обращения
          </div>
        </div>
        <div class="nf-tabs__tabs-inner" :style="innerBorderStyle">
          <div class="nf-tabs__tabs-text" v-show="activeTab === 0">
            <div>
              Ребенок от 2 беременности, протекавшей на фоне угрозы прерывания,
              истмико-цервикальная недостаточность (хирургическая коррекция).
              Срочные, самостоятельные роды. Неонатальная желтуха.
            </div>
            <div class="mt-2">
              <strong>Масса тела при рождении:</strong> 3600 г.
            </div>
            <div class="mt-1"><strong>Длина тела:</strong> 52 см.</div>
            <div class="mt-1">
              <strong>Оценка по шкале Апгар:</strong> 8/8 баллов.
            </div>
          </div>
          <div class="nf-tabs__tabs-text" v-show="activeTab === 1">
            <div>Анамнез по НФ1 не отягощен.</div>
            <div class="mt-1">
              <strong>Брат:</strong> 18 лет, объемное образование верхней
              конечности, планируется обследование.
            </div>
            <div class="mt-1">
              <strong>Отец:</strong> ИБС, стентирование сосудов сердца.
            </div>
            <div class="mt-1"><strong>Мать:</strong> здорова.</div>
            <div class="mt-1">
              <strong>Мать и отец матери:</strong> СД 2 типа.
            </div>
            <div class="mt-1">
              <strong>Мать отца:</strong> рак молочной железы.
            </div>
          </div>
          <div class="nf-tabs__tabs-text" v-show="activeTab === 2">
            <ul>
              <li>
                Наличие объемного образования в левой надключичной области (со
                слов пациентки, увеличивающегося в размерах).
              </li>
              <li>Слабая боль.</li>
              <li>Связанное с образованием двигательное нарушение.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NFtabs",
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    innerBorderStyle() {
      if (this.activeTab === 0) {
        return {
          "border-radius": "0px 8px 8px 8px",
        };
      } else if (this.activeTab === 2) {
        return {
          "border-radius": "8px 8px 8px 0px",
        };
      } else {
        return {
          "border-radius": "8px",
        };
      }
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style lang="scss">
.nf-tabs {
  border: 1px solid #d2d2d2;
  border-radius: 32px;
  &__header {
    padding: 24px 32px;
    border-radius: 32px 32px 0 0;
    background-color: #a04b8e;
    @media screen and(max-width: 1199px) {
      padding: 24px;
    }
    @media screen and(max-width: 767px) {
      padding: 16px;
    }
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-text {
      margin-left: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
    }
  }
  &__tabs {
    &-content {
      padding: 24px 32px;
      display: flex;
      width: 100%;
      @media screen and(max-width: 1199px) {
        padding: 24px;
      }
      @media screen and(max-width: 767px) {
        padding: 16px;
      }
    }
    &-list {
      width: 275px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media screen and(max-width: 1199px) {
        width: 216px;
      }
      @media screen and(max-width: 767px) {
        min-width: 92px;
        max-width: 92px;
      }
    }
    &-btn {
      cursor: pointer;
      padding: 26px 16px;
      max-width: 251px;
      background-color: #f8f8f8;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      &.active {
        border: none;
        max-width: 100%;
        border-radius: 8px 0px 0px 8px;
        background-color: #f4eff4;
      }
      @media screen and(max-width: 1199px) {
        max-width: 200px;
        padding: 32px 16px;
      }
      @media screen and(max-width: 767px) {
        min-width: 80px;
        max-width: 87px;
        font-size: 12px;
        line-height: 16px;
        padding: 12px 8px;
      }
    }
    &-inner {
      padding: 24px 24px 24px 0;
      max-width: 461px;
      width: 100%;
      background-color: #f4eff4;
      @media screen and(max-width: 1199px) {
        max-width: 328px;
      }
      @media screen and(max-width: 767px) {
        padding: 16px 16px 16px 0;
        max-width: 100%;
      }
    }
    &-text {
      padding-left: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
      @media screen and(max-width: 767px) {
        padding-left: 16px;
      }
    }
  }
}
</style>
