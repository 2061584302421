<template>
  <div class="kanuma-calc">
    <div class="kanuma-calc__title">
      Расчет разовой дозы препарата Канума<sup>&reg;</sup> в мл на одно введение:
    </div>
    <div class="kanuma-calc__row">
      <div class="kanuma-calc__item">
        <div class="kanuma-calc__item-title">
          Для пациентов, получающих дозу 1 мг/кг:
        </div>
        <div class="kanuma-calc__item-row">
          <input
            :class="{ 'kanuma-calc__item-input_error': error1 }"
            v-model="weight1"
            type="number"
            placeholder="кг"
            class="kanuma-calc__item-input"
          />
          <div class="kanuma-calc__item-input kanuma-calc__item-input_disabled">
            Доза 1 мг/кг
          </div>
          <div
            class="kanuma-calc__item-button button button_pink"
            @click="result(1)"
          >
            Рассчитать
          </div>
        </div>
        <div class="kanuma-calc__item-result">
          <div class="kanuma-calc__item-result-label">Канума<sup>&reg;</sup>, мл</div>
          <div class="kanuma-calc__item-result-value">
            {{ result1 }}
          </div>
        </div>
      </div>
      <div class="kanuma-calc__item">
        <div class="kanuma-calc__item-title">
          Для детей раннего возраста с инфантильной формой ДЛКЛ, получающих
          повышенную дозу 3 мг/кг:
        </div>
        <div class="kanuma-calc__item-row">
          <input
            :class="{ 'kanuma-calc__item-input_error': error2 }"
            v-model="weight2"
            type="number"
            placeholder="кг"
            class="kanuma-calc__item-input"
          />
          <div class="kanuma-calc__item-input kanuma-calc__item-input_disabled">
            Доза 3 мг/кг
          </div>
          <div
            class="kanuma-calc__item-button button button_pink"
            @click="result(3)"
          >
            Рассчитать
          </div>
        </div>
        <div class="kanuma-calc__item-result">
          <div class="kanuma-calc__item-result-label">Канума<sup>&reg;</sup>, мл</div>
          <div class="kanuma-calc__item-result-value">
            {{ result2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "kanumaCalc",
  data: () => ({
    weight1: null,
    weight2: null,
    error1: null,
    error2: null,
    result1: null,
    result2: null,
  }),
  methods: {
    result(multiplier) {
      if (multiplier === 1) {
        if (!this.weight1) {
          this.$set(this, "error1", true);
          setTimeout(() => {
            this.$set(this, "error1", false);
          }, 1000);
        } else {
          this.$set(this, "result1", this.weight1 / 2);
        }
      }
      if (multiplier === 3) {
        if (!this.weight2) {
          this.$set(this, "error2", true);
          setTimeout(() => {
            this.$set(this, "error2", false);
          }, 1000);
        } else {
          this.$set(this, "result2", (this.weight2 * 3) / 2);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kanuma-calc {
  padding: 32px 24px;
  background-color: #f8f8f8;
  border: 1px solid #d2d2d2;
  @media screen and (max-width: 767px) {
    border: 0;
    background-color: #fff;
    padding: 0;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #4f0b7b;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #d2d2d2;

    &-title {
      margin-bottom: auto;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
    }

    &-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 16px 0;
      gap: 16px;
      @media screen and (max-width: 767px) {
        gap: 8px;
      }
    }

    &-input {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #3c4242;
      border: 1px solid #d2d2d2;
      background-color: #fff;
      border-radius: 999px;
      @media screen and (max-width: 767px) {
        padding: 0 10px;
      }

      &_error {
        color: rgb(214, 3, 3);
        border-color: rgb(214, 3, 3);
      }

      &_disabled {
        background-color: #f8f8f8;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
    }

    &-result {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &-label {
        padding: 10px 0;
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4f0b7b;
      }

      &-value {
        padding: 10px 0;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #1f1f1f;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #4f0b7b;
      }
    }
  }
}
</style>