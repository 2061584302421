<template>
  <div class="png-other">
    <div class="png-other__container mb-12">
      <div class="png-other__tabs">
        <div
          class="png-other__tabs-btn"
          @click="changeTab(0)"
          :class="{ active: activeTab === 0 }"
        >
          <div class="png-other__tabs-btn_wrap">Тромбоз</div>
        </div>
        <div
          class="png-other__tabs-btn"
          @click="changeTab(1)"
          :class="{ active: activeTab === 1 }"
        >
          <div class="png-other__tabs-btn_wrap">ХБП</div>
        </div>
        <div
          class="png-other__tabs-btn"
          @click="changeTab(2)"
          :class="{ active: activeTab === 2 }"
        >
          <div class="png-other__tabs-btn_wrap">Анемия</div>
        </div>
        <div
          class="png-other__tabs-btn"
          @click="changeTab(3)"
          :class="{ active: activeTab === 3 }"
        >
          <div class="png-other__tabs-btn_wrap">Повышенная утомляемость</div>
        </div>
      </div>
      <div class="png-other__content" :style="innerBorderStyle">
        <div class="png-other__content-block" v-show="activeTab === 0">
          <div class="png-other__content-text">
            <div>
              Тромбоз является наиболее разрушительным осложнением ПНГ,
              и во многих исследованиях тромбоз указывается как основная причина
              смерти при ПНГ.
            </div>
            <br />
            <div>
              У пациентов с ПНГ часто отмечается тромбоз в анамнезе. У пациентов
              с ПНГ и наличием тромбоза на момент осмотра:
            </div>
            <br />
            <ul style="padding-left: 25px">
              <li>относительный риск смерти увеличен в 5–10 раз;</li>
              <li>
                4-летняя выживаемость с момента постановки диагноза составляет
                40%.
              </li>
            </ul>
            <br />
            <div><b>Локализация тромбоза</b></div>
            <br />
            <div>
              Тромбоз при ПНГ носит комплексный характер и может возникать
              в любых органах, включая сердце, печень, почки, головной мозг
              и лёгкие. Примерно в 35% случаев отмечается множественная
              локализация. Тромбы у пациентов с ПНГ могут располагаться
              как в типичных (например, глубокие вены, эмболия лёгочной
              артерии), так и в менее типичных местах. <br />
              По причинам, которые до сих пор неясны, следующие локализации
              тромбоза часто встречаются при ПНГ, но реже в общей популяции:
            </div>
            <br />
            <ul style="padding-left: 25px">
              <li>
                внутрибрюшные вены, особенно печёночные вены (синдром
                Бадда-Киари);
              </li>
              <li>церебральные вены.</li>
            </ul>
            <br />
            <div>
              Даже если тромбоз в этих органах не приводит к смерти,
              он сопровождается симптомами, снижающими качество жизни. Острая
              или хроническая боль в животе может быть вызвана микротромбами
              в печёночных венах. Тромбоз церебральных вен может вызывать такие
              симптомы, как сильная головная боль, рвота и судороги.
            </div>
            <br />
            <div>
              Хотя тромбоз обычно возникает в венах, также поражаются артерии:
              до 31% тромбоэмболических (ТЭ) событий происходит в артериях,
              включая церебральные и коронарные.
            </div>
          </div>
        </div>
        <div class="png-other__content-block" v-show="activeTab === 1">
          <div class="png-other__content-text">
            <b>Хроническая болезнь почек (ХБП)</b>
            <br />
            <br>
            <div>
              Пациенты с ПНГ и почечной недостаточностью характеризуются значимо
              более высокой (P &#60; 0,0001) распространённостью тромбоза (38%)
              по сравнению с пациентами без почечной недостаточности (14%),
              что указывает на возможную роль тромбоза в развитии ХБП. ХБП сама
              по себе является угрожающим проявлением ПНГ, но при ПНГ развитие
              ХБП имеет определённые клинические особенности. Предикторами
              почечной недостаточности являются такие клинические симптомы,
              как гемоглобинурия (P &#60; 0,0001) и боль в животе (P &#60; 0,0001).
            </div>
          </div>
        </div>
        <div
          class="png-other__content-block third-block"
          v-show="activeTab === 2"
        >
          <div class="png-other__content-text">
            <div>
              У многих пациентов с ПНГ при первичном обследовании определяется
              анемия. Она зачастую обусловлена множеством этиологических
              факторов, ведущими из которых являются внутрисосудистый гемолиз
              и недостаточность костного мозга. Напомним, что ПНГ-эритроциты
              более чувствительны к опосредованному комплементом лизису
              вследствие дефицита или полного отсутствия GPI-связанных белков
              CD55 и CD59. Это приводит к внутрисосудистому гемолизу,
              характерному для ПНГ.
            </div>
            <br />
            <div>
              Пациентам с тяжёлой анемией может быть показана гемотрансфузия.
              В крупном исследовании у 460 пациентов с ПНГ 50% пациентов
              с гемолизом нуждались в гемотрансфузии в течение 12 месяцев
              после постановки диагноза. Однако, вопреки историческим
              представлениям, у пациентов с ПНГ интенсивность гемотрансфузий
              в анамнезе или в настоящее время не является индикатором
              клинических рисков, связанных с гемолизом. Пациенты, которым
              не требуются гемотрансфузии, имеют сходную частоту гемолиза
              и связанных с гемолизом симптомов, включая тромбоз, боль в животе,
              одышку и утомляемость.
            </div>
          </div>
        </div>
        <div class="png-other__content-block" v-show="activeTab === 3">
          <div class="png-other__content-text">
            <div>
              Значение повышенной утомляемости при ПНГ часто недооценивается; в
              прошлом это нарушение приписывалось сопутствующей анемии. Однако
              клинические исследования показали, что утомляемость у пациентов с
              ПНГ:
            </div>
            <br />
            <ul style="padding-left: 25px">
              <li>непосредственно связана с внутрисосудистым гемолизом;</li>
              <li>поразительно непропорциональна выраженности анемии;</li>
              <li>зачастую носит изнурительный характер;</li>
              <li>
                по степени тяжести сходна с таковой у пациентов со
                злокачественными заболеваниями.
              </li>
            </ul>
            <br />
            <div>
              Утомляемость испытывают 80% пациентов с ПНГ, при этом 77%
              пациентов оценивают её как умеренную или тяжёлую. Среди страдающих
              утомляемостью 85% сообщают о том, что испытывают связанный
              с ней стресс.
            </div>
            <br />
            <div>
              Традиционные методы лечения анемии, такие как трансфузия
              эритроцитарной массы, эритропоэтин и фолиевая кислота, могут быть
              эффективными в отношении продукции эритроцитов. Однако
              они не предотвращают опосредованный комплементом гемолиз, который
              является основной причиной прогрессирующих нарушений, включая
              утомляемость, и преждевременной смертности у пациентов с ПНГ.
              Концентрация гемоглобина не отражает выраженность утомляемости
              у пациентов с ПНГ.
            </div>
            <div>
              Утомляемость при ПНГ приводит к снижению качества жизни пациентов,
              поскольку многие из них не могут работать, работают меньше часов
              или пропускают рабочие дни.
            </div>
            <br />
            <div>
              Пациенты с ПНГ обычно испытывают сильную утомляемость, аналогично
              пациентам с другими хроническими заболеваниями
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PNGTabs",
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    innerBorderStyle() {
      if (this.activeTab === 0) {
        return {
          "border-radius": "0px 8px 8px 8px",
        };
      } else if (this.activeTab === 3) {
        return {
          "border-radius": "8px 0px 8px 8px",
        };
      } else {
        return {
          "border-radius": "8px",
        };
      }
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    scrollToTabTopAndChangeTab(index) {
      this.changeTab(index);
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        setTimeout(() => {
          const tabBtn = document.querySelector(
            `.png-other__tabs-btn:nth-child(${index + 1})`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
        }, 100); // Задержка для обеспечения корректной смены таба перед прокруткой
      }
    },
    getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
  },
};
</script>

<style lang="scss">
.png-other {
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: 108px;
    @media screen and(max-width: 1199px) {
    }
    @media screen and(max-width: 767px) {
      max-height: 120px;
      gap: 8px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      padding: 14px 24px;
      width: 100%;
      height: 92px;
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      &_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        @media screen and(max-width: 767px) {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          word-break: break-all;
        }
      }
      &.active {
        border: none;
        height: 108px;
        border-radius: 8px 8px 0px 0px;
        background-color: #FCEECC80;
        @media screen and(max-width: 767px) {
        }
      }
      @media screen and(max-width: 1199px) {
        max-width: 186px;
        height: 72px;
      }
      @media screen and(max-width: 767px) {
        font-weight: 500;
        padding:16px 8px;
      }
    }
  }
  &__content {
    padding: 24px;
    background-color: #FCEECC80;
    @media screen and(max-width: 1199px) {
      padding: 24px 16px;
    }
    &-block {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      align-items: center;
      @media screen and(max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__main {
    &-middle {
      display: flex;
      flex-direction: row;
      gap: 24px;
      @media screen and(max-width: 1199px) {
        flex-direction: column;
      }
      &_item {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        img {
          flex-shrink: 0;
          width: 134px;
          height: 134px;
          @media screen and (max-width: 767px) {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }
}
</style>
