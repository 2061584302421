<template>
  <div class="nf-other">
    <div class="nf-other__container mb-12">
      <div class="nf-other__tabs">
        <div
          class="nf-other__tabs-btn"
          @click="changeTab(0)"
          :class="{ active: activeTab === 0 }"
        >
          <div class="nf-other__tabs-btn_wrap">
            <div class="nf-other__tabs-icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="19.5" stroke="#9A3374" />
                <g clip-path="url(#clip0_6841_102474)">
                  <path
                    d="M23.0002 15L16.5002 21.5C16.1024 21.8978 15.8789 22.4374 15.8789 23C15.8789 23.5626 16.1024 24.1022 16.5002 24.5C16.898 24.8978 17.4376 25.1213 18.0002 25.1213C18.5628 25.1213 19.1024 24.8978 19.5002 24.5L26.0002 18C26.7958 17.2043 27.2428 16.1252 27.2428 15C27.2428 13.8748 26.7958 12.7956 26.0002 12C25.2045 11.2043 24.1254 10.7574 23.0002 10.7574C21.875 10.7574 20.7958 11.2043 20.0002 12L13.5002 18.5C12.3067 19.6935 11.6362 21.3122 11.6362 23C11.6362 24.6878 12.3067 26.3065 13.5002 27.5C14.6937 28.6935 16.3124 29.364 18.0002 29.364C19.688 29.364 21.3067 28.6935 22.5002 27.5L29.0002 21"
                    stroke="#9A3374"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6841_102474">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            Анамнез заболевания
          </div>
        </div>
        <div
          class="nf-other__tabs-btn"
          @click="changeTab(1)"
          :class="{ active: activeTab === 1 }"
        >
          <div class="nf-other__tabs-btn_wrap">
            <div class="nf-other__tabs-icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="19.5" stroke="#9A3374" />
                <path
                  d="M20.2312 13.889C21.6598 13.889 22.8179 12.7309 22.8179 11.3023C22.8179 9.87374 21.6598 8.71565 20.2312 8.71565C18.8026 8.71565 17.6445 9.87374 17.6445 11.3023C17.6445 12.7309 18.8026 13.889 20.2312 13.889Z"
                  stroke="#9A3374"
                />
                <path
                  d="M28.7248 22.8534C30.1534 22.8534 31.3115 21.6953 31.3115 20.2668C31.3115 18.8382 30.1534 17.6801 28.7248 17.6801C27.2963 17.6801 26.1382 18.8382 26.1382 20.2668C26.1382 21.6953 27.2963 22.8534 28.7248 22.8534Z"
                  stroke="#9A3374"
                />
                <path
                  d="M20.2312 31.2843C21.6598 31.2843 22.8179 30.1262 22.8179 28.6976C22.8179 27.269 21.6598 26.1109 20.2312 26.1109C18.8026 26.1109 17.6445 27.269 17.6445 28.6976C17.6445 30.1262 18.8026 31.2843 20.2312 31.2843Z"
                  stroke="#9A3374"
                />
                <path
                  d="M11.6272 27.4886C13.0558 27.4886 14.2139 26.3305 14.2139 24.9019C14.2139 23.4734 13.0558 22.3153 11.6272 22.3153C10.1986 22.3153 9.04053 23.4734 9.04053 24.9019C9.04053 26.3305 10.1986 27.4886 11.6272 27.4886Z"
                  stroke="#9A3374"
                />
                <path
                  d="M11.6272 17.3731C13.0558 17.3731 14.2139 16.215 14.2139 14.7864C14.2139 13.3578 13.0558 12.1998 11.6272 12.1998C10.1986 12.1998 9.04053 13.3578 9.04053 14.7864C9.04053 16.215 10.1986 17.3731 11.6272 17.3731Z"
                  stroke="#9A3374"
                />
                <path
                  d="M20.2266 21.3202C21.3802 21.3202 22.3155 20.385 22.3155 19.2313C22.3155 18.0777 21.3802 17.1424 20.2266 17.1424C19.0729 17.1424 18.1377 18.0777 18.1377 19.2313C18.1377 20.385 19.0729 21.3202 20.2266 21.3202Z"
                  stroke="#9A3374"
                />
                <path d="M13.8535 13.4668L17.7335 11.969" stroke="#9A3374" />
                <path d="M22.0444 13.1512L26.88 18.4534" stroke="#9A3374" />
                <path d="M27.1424 22.3112L22.0757 26.8846" stroke="#9A3374" />
                <path d="M13.0488 16.9465L18.6266 26.6709" stroke="#9A3374" />
                <path d="M13.9155 26.1069L17.6444 27.8358" stroke="#9A3374" />
                <path d="M12.5957 22.5019L18.8313 13.4753" stroke="#9A3374" />
                <path d="M14.0093 15.8002L18.3737 18.2669" stroke="#9A3374" />
                <path d="M22.3198 19.4664L26.1376 19.7553" stroke="#9A3374" />
                <path d="M20.2266 21.3246L20.231 26.1068" stroke="#9A3374" />
              </svg>
            </div>
            Исследования и диагноз
          </div>
        </div>
        <div
          class="nf-other__tabs-btn"
          @click="changeTab(2)"
          :class="{ active: activeTab === 2 }"
        >
          <div class="nf-other__tabs-btn_wrap">
            <div class="nf-other__tabs-icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="19.5" stroke="#9A3374" />
                <path
                  d="M15.8824 15.8824H10V24.1176H15.8824V30H24.1176V24.1176H30V15.8824H24.1176V10H15.8824V15.8824Z"
                  stroke="#9A3374"
                />
              </svg>
            </div>
            Лечение и МРТ
          </div>
        </div>
      </div>
      <div class="nf-other__content" :style="innerBorderStyle">
        <div class="nf-other__content-block" v-show="activeTab === 0">
          <div class="nf-other__content-sect">
            <div class="nf-other__content-date mb-4">В 1 месяц</div>
            <div class="nf-other__content-text mb-4">
              <div class="mb-2">
                Стафилококковая инфекция: псевдофурункулез левого плеча
              </div>
              <div class="mb-2">Правосторонняя нижнедолевая пневмония</div>
              <div class="mb-2">
                Получала антибактериальную терапию, проводилась
                физиотерапевтическая процедура электрофореза шейно-воротниковой
                и поясничной зон
              </div>
              <div class="mb-2">
                Вскоре отмечено появление пятна цвета «кофе с молоком»
              </div>
            </div>
          </div>
          <div class="nf-other__content-sect">
            <div class="nf-other__content-date mb-4">В 1 - 12 месяцев</div>
            <div class="nf-other__content-text mb-4">
              <div class="mb-2">Атопический дерматит</div>
              <div class="mb-2">Задержка нервно-психического развития</div>
              <div class="mb-2">Задержка речевого развития</div>
              <div class="mb-2">
                Посещала специальный логопедический детский сад
              </div>
            </div>
          </div>
          <div class="nf-other__content-sect">
            <div class="nf-other__content-date mb-4">В 1 год</div>
            <div class="nf-other__content-text mb-4">
              <div class="nf-other__content-light">
                <div class="nf-other__content-light_svg">
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.4431 11.287C26.1812 11.287 28.4009 9.06734 28.4009 6.32924C28.4009 3.59113 26.1812 1.37146 23.4431 1.37146C20.705 1.37146 18.4854 3.59113 18.4854 6.32924C18.4854 9.06734 20.705 11.287 23.4431 11.287Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M39.7219 28.469C42.46 28.469 44.6797 26.2493 44.6797 23.5112C44.6797 20.7731 42.46 18.5534 39.7219 18.5534C36.9838 18.5534 34.7642 20.7731 34.7642 23.5112C34.7642 26.2493 36.9838 28.469 39.7219 28.469Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M23.4431 44.6285C26.1812 44.6285 28.4009 42.4088 28.4009 39.6707C28.4009 36.9326 26.1812 34.7129 23.4431 34.7129C20.705 34.7129 18.4854 36.9326 18.4854 39.6707C18.4854 42.4088 20.705 44.6285 23.4431 44.6285Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M6.95094 37.3536C9.68905 37.3536 11.9087 35.1339 11.9087 32.3958C11.9087 29.6577 9.68905 27.438 6.95094 27.438C4.21284 27.438 1.99316 29.6577 1.99316 32.3958C1.99316 35.1339 4.21284 37.3536 6.95094 37.3536Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M6.95094 17.9653C9.68905 17.9653 11.9087 15.7457 11.9087 13.0076C11.9087 10.2694 9.68905 8.04977 6.95094 8.04977C4.21284 8.04977 1.99316 10.2694 1.99316 13.0076C1.99316 15.7457 4.21284 17.9653 6.95094 17.9653Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M23.4344 25.5301C25.6456 25.5301 27.4381 23.7376 27.4381 21.5264C27.4381 19.3152 25.6456 17.5227 23.4344 17.5227C21.2232 17.5227 19.4307 19.3152 19.4307 21.5264C19.4307 23.7376 21.2232 25.5301 23.4344 25.5301Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M11.2188 10.4776L18.6554 7.60684"
                      stroke="#9A3374"
                    />
                    <path
                      d="M26.9185 9.87292L36.1866 20.0355"
                      stroke="#9A3374"
                    />
                    <path
                      d="M36.6891 27.4297L26.978 36.1953"
                      stroke="#9A3374"
                    />
                    <path
                      d="M9.67725 17.1476L20.368 35.7861"
                      stroke="#9A3374"
                    />
                    <path
                      d="M11.3384 34.7046L18.4854 38.0183"
                      stroke="#9A3374"
                    />
                    <path
                      d="M8.80811 27.796L20.7596 10.4949"
                      stroke="#9A3374"
                    />
                    <path
                      d="M11.5171 14.9502L19.8823 19.678"
                      stroke="#9A3374"
                    />
                    <path
                      d="M27.4468 21.9776L34.7642 22.5313"
                      stroke="#9A3374"
                    />
                    <path
                      d="M23.4346 25.5385L23.4431 34.7045"
                      stroke="#9A3374"
                    />
                  </svg>
                </div>
                <div class="nf-other__content-light_text">
                  <div>
                    Консультация генетика в СПб ГКУЗ "Диагностический центр
                    (медико-генетический)", г. Санкт-Петербург
                  </div>
                  <div>
                    По совокупности клинических проявлений установлен диагноз
                  </div>
                  <div><span>Нейрофиброматоз 1 типа</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="nf-other__content-sect">
            <div class="nf-other__content-date mb-4">
              В 1 год – 12 лет 11 месяцев
            </div>
            <div class="nf-other__content-text mb-4">
              <div class="mb-2">Развитие без особенностей</div>
            </div>
          </div>
          <div class="nf-other__content-sect">
            <div class="nf-other__content-date mb-4">В 12 лет 11 месяцев</div>
            <div class="nf-other__content-text mb-4">
              <div class="mb-2">
                01.2022 г. заметили появление
                <span>опухолевидного образования</span> слева в надключичной
                области, после полученной травмы в школе (на девочку упал
                плафон)
              </div>
              <div class="red-arrow_up mb-2">
                Образование быстро увеличивалось в размерах
              </div>
              <div class="nf-other__content-test">
                <div class="nf-other__content-test_head mb-4">
                  <div class="nf-other__content-test_svg">
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21.9167"
                        cy="21.5"
                        r="17.4167"
                        stroke="#830051"
                      />
                      <path
                        d="M20.296 25.4619H22.708V27.7119H20.296V25.4619ZM20.386 14.8599H22.636V18.5679L22.042 24.1299H20.98L20.386 18.5679V14.8599Z"
                        fill="#830051"
                      />
                    </svg>
                  </div>
                  <div class="nf-other__content-test_text">
                    <div>
                      После прохождения вопроса будет доступна остальная
                      информация по анамнезу заболевания
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  {{ tabData[0].question }}
                </div>
                <div
                  v-for="(option, optionIndex) in tabData[0].options"
                  :key="optionIndex"
                >
                  <label>
                    <input
                      type="radio"
                      :value="optionIndex"
                      v-model="tabData[0].selectedOption"
                    />
                    {{ option }}
                  </label>
                </div>
                <div class="nf-other__content-test_btn" @click="submitAnswer">
                  Ответить
                </div>
              </div>
            </div>
          </div>
          <div class="after-test__sect" v-if="tabData[0].showAdditionalSection">
            <div class="nf-other__content-sect">
              <div class="nf-other__content-date mb-4">В 13 лет</div>
              <div class="nf-other__content-text mb-4">
                <div class="nf-other__content-light">
                  <div class="nf-other__content-light_text">
                    <div class="nf-other__content-light_text-title">
                      Рентгенография ОГК от 10.02.2022 г. (выполнена
                      амбулаторно)
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      Верхушка левого легкого затенена за счет мягкотканного
                      компонента с четким ровным нижним контуром.
                    </div>
                  </div>
                </div>
                <div class="mt-4 mb-4">
                  02.2022 г. госпитализация в ФГБУ «НМИЦ им. В. А.Алмазова»
                  Минздрава России, <br />
                  г. Санкт-Петербург
                </div>
                <div class="nf-other__content-light mb-2">
                  <div class="nf-other__content-light_text">
                    <div class="nf-other__content-light_text-title">
                      C целью первичной оценки локализации и распространенности
                      новообразования выполнена МСКТ органов грудной клетки и
                      шеи (03.03.2022 г.)*
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        Образования шеи и заднего средостения слева, неоднородно
                        накапливающие контрастное вещество
                      </div>
                      <div class="mb-2">
                        Образование в паратрахеальной правой области 1х1,6х1,6
                        см.
                      </div>
                      <div>
                        Паравазально слева на уровне дуги аорты, кпереди от
                        брахиоцефального ствола 1,7х2,1х4,0 см.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-2">
                  <div class="nf-other__content-light_text">
                    <div class="nf-other__content-light_text-title">
                      С целью дальнейшей оценки характеристик опухоли выполнена
                      МРТ шейно-грудного отдела позвоночника (05.03.2022 г.)*
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-4">
                        Выявлены образования:
                        <ul class="mt-2">
                          <li>на уровне C6-Th1 (4,2х5,8х2,7 см) слева,</li>
                          <li>на уровне Th1-Th3 (5,6х4,3 х 5,0 см) слева,</li>
                          <li>
                            с распространением в позвоночный канал через левые
                            межпозвонковые отверстия,
                          </li>
                          <li>
                            с резким смещением спинного мозга вправо без
                            признаков инвазии и компрессии,
                          </li>
                          <li>образование на уровне Th3-Th4 справа.</li>
                        </ul>
                      </div>
                      <div class="nf-other__content-light_text-flex">
                        <div>
                          <img
                            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/1.jpg"
                            alt=""
                          />
                        </div>
                        <div>
                          <img
                            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/2.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="nf-other__content-light_text-light-tip mt-4">
                        *ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава России, г.
                        Санкт-Петербург
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nf-other__content-sect">
              <div class="nf-other__content-date mb-4">В 13 лет 1 месяц</div>
              <div class="nf-other__content-text mb-4">
                <div class="mb-4">
                  Дообследование в ДОГиТКМФГБУ «НМИЦ им. В. А.Алмазова»
                  Минздрава России, <br />
                  г. Санкт-Петербург
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>
                      С целью дополнительной оценки распространенности
                      новообразования выполнена МСКТ мягких тканей шеи и ОГК с
                      к/у (30.03.2022 г.)*:
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        <span
                          >Единичные образования в левой надключичной области,
                          верхнем этаже средостения и апикальном отделе левого
                          легкого</span
                        >
                        (проявление лимфопролиферативного заболевания?).
                      </div>
                      <div>
                        Единичные хаотично расположенные очаги в легких
                        (вероятно, резидуального характера).
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>
                      С целью уточнения характера новообразования выполнена
                      ПЭТ-КТ всего тела <br />
                      с 18F-фтордезоксиглюкозой (ФДГ) (31.03.2022 г.)*:
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        ПЭТ-КТ-картина
                        <span
                          >образований центрального и заднего средостения слева,
                          в надключичной области слева, паравазально слева на
                          уровне дуги аорты</span
                        >, с патологической гиперфиксацией 18F-ФДГ.
                      </div>
                      <div class="mb-2">
                        <span
                          >Мягкотканное уплотнение в области ромбовидной и
                          трапециевидных мышц слева </span
                        >неясного генеза.
                      </div>
                      <div class="red-arrow_up mb-2">
                        ПЭТ-КТ признаки увеличения матки в размерах (размеры
                        превышают возрастную норму),
                      </div>
                      <div class="mb-2">
                        Гиподенсные образования обоих яичников сгиперфиксацией
                        РФП (вероятно, кисты).
                      </div>
                      <div class="red-arrow_up">
                        Повышена метаболическая активность костного мозга.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>МРТ спинного мозга с к/у*:</div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        МР-картина
                        <span
                          >объемных образований паравертебральных мягких тканей
                          и заднего средостения</span
                        >, с фораменальными и интрадуральным компонентами
                        (нейрофибромы).
                      </div>
                      <div>
                        По сравнению с данными МРТ от 05.03.2022 г. – без
                        динамики.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>МРТ головного мозга с к/у*:</div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        Наличие
                        <span
                          >очаговых изменений головного мозга, наиболее
                          вероятно, проявление нейрофиброматоза 1 типа.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-8">
                  <div class="nf-other__content-light_text">
                    <div>Осмотрена неврологом*:</div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        На момент осмотра общемозговой и очаговой
                        неврологической симптоматики не выявлено
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <span
                    >Обследование в ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава
                    России, г. Санкт-Петербург</span
                  >
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>
                      Ввиду подозрения на злокачественный характер
                      новообразования (быстрый рост, патологическая
                      гиперфиксация 18-F ФДГ) с целью проведения гистологической
                      верификации 04.04.2022 г. выполнена биопсия образования
                      надключичной области слева*
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        Гистологическое исследование**:
                        <span>плексиформная нейрофиброма.</span>
                      </div>
                    </div>
                    <div class="nf-other__content-light_text-light-tip mt-4">
                      *ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава России, г.
                      Санкт-Петербург<br />
                      **ФГБУ «НМИЦ ДГОИ им. Дмитрия Рогачева» Минздрава России,
                      г. Москва
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>
                      В связи с тем, что при проведении ПЭТ/КТ отмечена
                      повышенная метаболическая активность костного мозга в
                      апреле 2022 г. выполнена трепанобиопсия костного мозга*
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        Морфологическое исследование, ИФТ костного мозга от
                        04.04.2020 г.*: миелограмма без особенностей.
                      </div>
                    </div>
                    <div class="nf-other__content-light_text-light-tip mt-4">
                      *ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава России, г.
                      Санкт-Петербург
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-light mb-4">
                  <div class="nf-other__content-light_text">
                    <div>
                      Решение консилиума НМИЦ им. В. А. Алмазова от 22.04.2022
                      г.:
                    </div>
                    <div class="nf-other__content-light_text-light-descr">
                      <div class="mb-2">
                        Учитывая стабильное состояние и отсутствие
                        неврологической симптоматики, показано динамическое
                        наблюдение, консервативная терапия.
                      </div>
                      <div class="mb-2">
                        При появлении неврологической симптоматики -
                        хирургическое лечение в экстренном порядке.
                      </div>
                    </div>
                    <div class="nf-other__content-light_text-light-tip mt-4">
                      *ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава России, г.
                      Санкт-Петербург
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nf-other__content-downsect">
            <div class="nf-other__content-downsect_btn-container mb-4">
              <div
                class="nf-other__content-downsect_btn button button_pink"
                v-if="tabData[0].showAdditionalSection"
                @click="scrollToTabTopAndChangeTab(1)"
              >
                Исследования и диагноз
              </div>
            </div>
            <div class="nf-other__content-downsect_tip">
              <div class="mb-2">
                Безъязычная И.В., врач-детский онколог ГУЗ «СПбКНпЦСВМП(о)»,
                г. Санкт-Петербург, из личного архива 2023 г.
              </div>
              <div>
                <a href="https://az-most.ru/medication/koselugo"
                  >https://az-most.ru/medication/koselugo</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="nf-other__content-block" v-show="activeTab === 1">
          <div class="nf-other__content-sect undoted">
            <div class="nf-other__content-text mb-4">
              <div class="nf-other__content-text_title mb-4">
                Молекулярно-генетическое исследование
              </div>

              <div class="nf-other__content-light mb-8">
                <div class="nf-other__content-light_text">
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.4431 11.2871C26.1812 11.2871 28.4009 9.06746 28.4009 6.32936C28.4009 3.59125 26.1812 1.37158 23.4431 1.37158C20.705 1.37158 18.4854 3.59125 18.4854 6.32936C18.4854 9.06746 20.705 11.2871 23.4431 11.2871Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M39.7219 28.4688C42.46 28.4688 44.6797 26.2491 44.6797 23.511C44.6797 20.7729 42.46 18.5532 39.7219 18.5532C36.9838 18.5532 34.7642 20.7729 34.7642 23.511C34.7642 26.2491 36.9838 28.4688 39.7219 28.4688Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M23.4431 44.6284C26.1812 44.6284 28.4009 42.4088 28.4009 39.6707C28.4009 36.9326 26.1812 34.7129 23.4431 34.7129C20.705 34.7129 18.4854 36.9326 18.4854 39.6707C18.4854 42.4088 20.705 44.6284 23.4431 44.6284Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M6.95094 37.3535C9.68905 37.3535 11.9087 35.1339 11.9087 32.3958C11.9087 29.6577 9.68905 27.438 6.95094 27.438C4.21284 27.438 1.99316 29.6577 1.99316 32.3958C1.99316 35.1339 4.21284 37.3535 6.95094 37.3535Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M6.95094 17.9654C9.68905 17.9654 11.9087 15.7457 11.9087 13.0076C11.9087 10.2695 9.68905 8.0498 6.95094 8.0498C4.21284 8.0498 1.99316 10.2695 1.99316 13.0076C1.99316 15.7457 4.21284 17.9654 6.95094 17.9654Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M23.4344 25.5299C25.6456 25.5299 27.4381 23.7373 27.4381 21.5262C27.4381 19.315 25.6456 17.5225 23.4344 17.5225C21.2232 17.5225 19.4307 19.315 19.4307 21.5262C19.4307 23.7373 21.2232 25.5299 23.4344 25.5299Z"
                      stroke="#9A3374"
                    />
                    <path
                      d="M11.2188 10.4777L18.6554 7.60693"
                      stroke="#9A3374"
                    />
                    <path
                      d="M26.9185 9.87305L36.1866 20.0356"
                      stroke="#9A3374"
                    />
                    <path
                      d="M36.6891 27.4297L26.978 36.1952"
                      stroke="#9A3374"
                    />
                    <path d="M9.67725 17.1475L20.368 35.786" stroke="#9A3374" />
                    <path
                      d="M11.3384 34.7046L18.4854 38.0183"
                      stroke="#9A3374"
                    />
                    <path
                      d="M8.80811 27.7962L20.7596 10.4951"
                      stroke="#9A3374"
                    />
                    <path
                      d="M11.5171 14.9502L19.8823 19.678"
                      stroke="#9A3374"
                    />
                    <path
                      d="M27.4468 21.9775L34.7642 22.5312"
                      stroke="#9A3374"
                    />
                    <path
                      d="M23.4346 25.5386L23.4431 34.7045"
                      stroke="#9A3374"
                    />
                  </svg>
                  <div class="mt-4 mb-2">
                    <span>Молекулярно-генетическая диагностика</span>
                  </div>
                  <div class="mb-2">Забор материала: 04.2022 г.</div>
                  <div class="mb-6">Дата выдачи результата: 06.2022 г.</div>
                  <div class="mb-2">
                    <span
                      >ФГБНУ «Медико-генетический научный центр имени академика
                      Н.П. Бочкова»,<br />
                      г. Москва</span
                    >
                  </div>
                  <div class="mb-2">
                    Определение мутаций в генах NF1, NF2 методом секвенирования
                    ДНК.
                  </div>
                  <div class="nf-other__content-light_text-arrow mb-2">
                    <svg
                      width="12"
                      height="23"
                      viewBox="0 0 12 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1L6.75 1ZM5.46967 22.5303C5.76256 22.8232 6.23744 22.8232 6.53033 22.5303L11.3033 17.7574C11.5962 17.4645 11.5962 16.9896 11.3033 16.6967C11.0104 16.4038 10.5355 16.4038 10.2426 16.6967L6 20.9393L1.75736 16.6967C1.46447 16.4038 0.989593 16.4038 0.6967 16.6967C0.403806 16.9896 0.403806 17.4645 0.6967 17.7574L5.46967 22.5303ZM5.25 1L5.25 22L6.75 22L6.75 1L5.25 1Z"
                        fill="#9A3374"
                      />
                    </svg>
                  </div>
                  <div class="mb-2">
                    <span>Подтвержден диагноз: Нейрофиброматоз 1 типа.</span>
                  </div>
                  <div class="mb-2">
                    Выявлена гетерозиготная мутация (делеция 1 нуклеотида) в 51
                    экзоне гена NF1: NM_000267: exon51:c.7674delG:pM2558fs
                  </div>
                </div>
              </div>

              <div class="nf-other__content-text_title mb-4">Диагноз</div>
              <div class="nf-other__content-text mb-8">
                <div class="nf-other__content-light">
                  <div class="nf-other__content-light_svg">
                    <svg
                      width="147"
                      height="104"
                      viewBox="0 0 147 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_6841_100488)">
                        <path
                          d="M120.954 52.2394C120.954 79.1827 99.1372 101.024 72.226 101.024C45.3149 101.024 23.498 79.1827 23.498 52.2394C23.498 25.296 45.3149 3.45508 72.226 3.45508C99.1372 3.45508 120.954 25.296 120.954 52.2394Z"
                          stroke="#1F1F1F"
                        />
                        <path
                          d="M61.0119 40.9945H47.6372C47.1477 40.9945 46.751 41.3912 46.751 41.8807V61.3384C46.751 61.8279 47.1477 62.2246 47.6372 62.2246H61.0119C61.5013 62.2246 61.8981 62.6214 61.8981 63.1108V76.5028C61.8981 76.9923 62.2948 77.389 62.7842 77.389H82.2178C82.7072 77.389 83.104 76.9923 83.104 76.5028V63.1108C83.104 62.6214 83.5007 62.2246 83.9901 62.2246H97.3648C97.8543 62.2246 98.251 61.8279 98.251 61.3384V41.8807C98.251 41.3912 97.8543 40.9945 97.3648 40.9945H83.9901C83.5007 40.9945 83.104 40.5977 83.104 40.1083V26.7163C83.104 26.2268 82.7072 25.8301 82.2178 25.8301H62.7842C62.2948 25.8301 61.8981 26.2268 61.8981 26.7163V40.1083C61.8981 40.5977 61.5013 40.9945 61.0119 40.9945Z"
                          stroke="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6841_100488">
                          <rect
                            width="103"
                            height="100.085"
                            fill="white"
                            transform="translate(21 2.19824)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="nf-other__content-light_text">
                    <div class="mb-2">
                      <span class="title-big"
                        >Диагноз: Нейрофиброматоз 1 типа.</span
                      >
                    </div>
                    <div>
                      Плексиформная нейрофиброма в области паравертебральных
                      мягких тканей и заднего средостения, с вовлечением левой
                      надключичной области.
                    </div>
                  </div>
                </div>
              </div>

              <div class="nf-other__content-test">
                <div class="nf-other__content-test_head mb-4">
                  <div class="nf-other__content-test_svg">
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21.9167"
                        cy="21.5"
                        r="17.4167"
                        stroke="#830051"
                      />
                      <path
                        d="M20.296 25.4619H22.708V27.7119H20.296V25.4619ZM20.386 14.8599H22.636V18.5679L22.042 24.1299H20.98L20.386 18.5679V14.8599Z"
                        fill="#830051"
                      />
                    </svg>
                  </div>
                  <div class="nf-other__content-test_text">
                    <div>
                      После ответа на вопрос будет доступна информация по
                      лечению
                    </div>
                  </div>
                </div>
                <div class="mb-2" v-html="tabData[1].question"></div>
                <div
                  v-for="(option, optionIndex) in tabData[1].options"
                  :key="optionIndex"
                >
                  <label>
                    <input
                      type="radio"
                      :value="optionIndex"
                      v-model="tabData[1].selectedOption"
                    />
                    {{ option }}
                  </label>
                </div>
                <div class="nf-other__content-test_btn" @click="submitAnswer">
                  Ответить
                </div>
              </div>
            </div>
          </div>
          <div class="after-test__sect" v-if="tabData[1].showAdditionalSection">
            <div class="nf-other__content-sect undoted">
              <div class="nf-other__content-text mb-4">
                <div class="nf-other__content-light mb-8">
                  <div class="nf-other__content-light_text">
                    <div class="mb-6">
                      В связи с тем, что рядом с ПН расположены анатомически
                      сложные структуры и жизненно важные органы, с учетом
                      возраста пациента, локализации и объема ПН, возможен риск
                      послеоперационных осложнений и рецидива. Хирургическое
                      лечение не показано.
                    </div>
                    <div class="mb-2">
                      С учетом неоперабельного и симптоматического характера ПН
                      (болевой синдром, двигательные нарушения на фоне ПН),
                      принято решение о проведении терапии препаратом
                      селуметиниб.
                    </div>
                  </div>
                </div>

                <div class="nf-other__content-text_title mb-4">
                  Рекомендованное лечение
                </div>
                <div class="nf-other__content-text">
                  <div class="nf-other__content-light">
                    <div class="nf-other__content-light_svg">
                      <svg
                        width="147"
                        height="104"
                        viewBox="0 0 147 104"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="51.0821"
                          y="69.2051"
                          width="81.7558"
                          height="37.3356"
                          rx="18.6678"
                          transform="rotate(-45 51.0821 69.2051)"
                          stroke="#3C4242"
                        />
                        <circle
                          cx="103.895"
                          cy="63.9352"
                          r="23.7311"
                          stroke="#3C4242"
                        />
                        <path
                          d="M88.2369 45.898L81.4219 39.083"
                          stroke="#3C4242"
                        />
                        <path
                          d="M83.207 76.1862L116.146 43.2471"
                          stroke="#3C4242"
                        />
                        <rect
                          x="26.7451"
                          y="16.7598"
                          width="49.7327"
                          height="12.2686"
                          stroke="#3C4242"
                        />
                        <rect
                          x="31.4268"
                          y="29.248"
                          width="40.3667"
                          height="58.3183"
                          stroke="#3C4242"
                        />
                        <path
                          d="M38.5146 41.2363H63.1005M38.5146 75.9687H60.3565"
                          stroke="#3C4242"
                        />
                      </svg>
                    </div>
                    <div class="nf-other__content-light_text">
                      <div class="mb-2">
                        <span class="title-big">Селуметиниб</span>
                      </div>
                      <div>Рекомендованное лечение: 70 мг/сутки</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nf-other__content-downsect">
            <div class="nf-other__content-downsect_btn-container mb-4">
              <div
                class="nf-other__content-downsect_btn button button_pink"
                v-if="tabData[1].showAdditionalSection"
                @click="scrollToTabTopAndChangeTab(2)"
              >
                Лечение и МРТ
              </div>
            </div>
            <div class="nf-other__content-downsect_tip">
              <div class="mb-2">
                Безъязычная И.В., врач-детский онколог ГУЗ «СПбКНпЦСВМП(о)»,
                г. Санкт-Петербург, из личного архива 2023 г.
              </div>
              <div>
                <a href="https://az-most.ru/medication/koselugo"
                  >https://az-most.ru/medication/koselugo</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="nf-other__content-block" v-show="activeTab === 2">
          <div class="nf-other__content-sect undoted">
            <div class="nf-other__content-text mb-4">
              <div class="nf-other__content-test">
                <div class="nf-other__content-test_head mb-4">
                  <div class="nf-other__content-test_svg">
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21.9167"
                        cy="21.5"
                        r="17.4167"
                        stroke="#830051"
                      />
                      <path
                        d="M20.296 25.4619H22.708V27.7119H20.296V25.4619ZM20.386 14.8599H22.636V18.5679L22.042 24.1299H20.98L20.386 18.5679V14.8599Z"
                        fill="#830051"
                      />
                    </svg>
                  </div>
                  <div class="nf-other__content-test_text">
                    <div>
                      После ответа на вопрос будет доступна информация по
                      лечению
                    </div>
                  </div>
                </div>
                <div class="mb-2" v-html="tabData[2].question"></div>
                <div
                  v-for="(option, optionIndex) in tabData[2].options"
                  :key="optionIndex"
                >
                  <label>
                    <input
                      type="checkbox"
                      :value="optionIndex"
                      v-model="tabData[2].selectedOption"
                    />
                    {{ option }}
                  </label>
                </div>
                <div class="nf-other__content-test_btn" @click="submitAnswer">
                  Ответить
                </div>
              </div>
            </div>
          </div>
          <div class="after-test__sect" v-if="tabData[2].showAdditionalSection">
            <div class="nf-other__content-sect undoted">
              <div class="nf-other__content-text">
                <div class="nf-other__content-text_title mb-4">Лечение</div>
                <div class="nf-other__content-text">
                  <div class="nf-other__content-light">
                    <div class="nf-other__content-light_reverse">
                      <div class="nf-other__content-light_text">
                        <div class="mb-4">
                          <span
                            >22.04.2022 г.— Начало терапии селуметинибом 70
                            мг/сут</span
                          >
                        </div>
                        <div class="mb-2">
                          Прием препарата селуметиниб по 35 мг х 2 раза в день
                        </div>
                        <div>Лечение без осложнений</div>
                      </div>
                      <div class="nf-other__content-light_svg-second">
                        <svg
                          width="89"
                          height="61"
                          viewBox="0 0 89 61"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="28.7462"
                            y="41.6641"
                            width="57.9221"
                            height="26.2949"
                            rx="13.1475"
                            transform="rotate(-45 28.7462 41.6641)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="66.1431"
                            cy="37.9127"
                            r="16.7525"
                            stroke="#1F1F1F"
                          />
                          <path
                            d="M54.2859 25.7829L49.4336 20.9307"
                            stroke="#1F1F1F"
                          />
                          <path
                            d="M52.1289 47.3472L75.5815 23.8945"
                            stroke="#1F1F1F"
                          />
                          <rect
                            x="11.3516"
                            y="5.89062"
                            width="35.1217"
                            height="8.44721"
                            stroke="#1F1F1F"
                          />
                          <rect
                            x="14.5"
                            y="14.5"
                            width="29"
                            height="42"
                            stroke="#1F1F1F"
                          />
                          <path
                            d="M20.3008 23.1738H37.8059M20.3008 47.9033H35.8522"
                            stroke="#1F1F1F"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="nf-other__content-light_text-arrow mb-2 mt-2">
                    <svg
                      width="12"
                      height="23"
                      viewBox="0 0 12 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1L6.75 1ZM5.46967 22.5303C5.76256 22.8232 6.23744 22.8232 6.53033 22.5303L11.3033 17.7574C11.5962 17.4645 11.5962 16.9896 11.3033 16.6967C11.0104 16.4038 10.5355 16.4038 10.2426 16.6967L6 20.9393L1.75736 16.6967C1.46447 16.4038 0.989593 16.4038 0.6967 16.6967C0.403806 16.9896 0.403806 17.4645 0.6967 17.7574L5.46967 22.5303ZM5.25 1L5.25 22L6.75 22L6.75 1L5.25 1Z"
                        fill="#9A3374"
                      ></path>
                    </svg>
                  </div>
                  <div class="nf-other__content-light">
                    <div class="nf-other__content-light_reverse">
                      <div class="nf-other__content-light_text">
                        <div class="mb-4">
                          <span
                            >06.2022 г.— Спустя 1,5 мес. после начала
                            терапии</span
                          >
                        </div>
                        <div class="mb-2">
                          Контрольный осмотр в ГУЗ "СПбКНпЦСВМП(о)", г.
                          Санкт-Петербург
                        </div>
                        <div class="mb-2">НЯ не отмечены</div>
                        <div>
                          Образование левой надключичной области без динамики
                        </div>
                      </div>
                      <div class="nf-other__content-light_svg-second">
                        <svg
                          width="63"
                          height="61"
                          viewBox="0 0 63 61"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="31.8272"
                            cy="30.0948"
                            r="29.1397"
                            stroke="#1F1F1F"
                          />
                          <path
                            d="M25.6082 23.3318H16.4883V36.0997H25.6082V45.2196H38.3761V36.0997H47.496V23.3318H38.3761V14.2119H25.6082V23.3318Z"
                            stroke="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="nf-other__content-light_text-arrow mb-2 mt-2">
                    <svg
                      width="12"
                      height="23"
                      viewBox="0 0 12 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1L6.75 1ZM5.46967 22.5303C5.76256 22.8232 6.23744 22.8232 6.53033 22.5303L11.3033 17.7574C11.5962 17.4645 11.5962 16.9896 11.3033 16.6967C11.0104 16.4038 10.5355 16.4038 10.2426 16.6967L6 20.9393L1.75736 16.6967C1.46447 16.4038 0.989593 16.4038 0.6967 16.6967C0.403806 16.9896 0.403806 17.4645 0.6967 17.7574L5.46967 22.5303ZM5.25 1L5.25 22L6.75 22L6.75 1L5.25 1Z"
                        fill="#9A3374"
                      ></path>
                    </svg>
                  </div>
                  <div class="nf-other__content-light">
                    <div class="nf-other__content-light_reverse">
                      <div class="nf-other__content-light_text">
                        <div class="mb-4">
                          <span
                            >08.2022 г.— Спустя ≈ 4 мес. после начала
                            терапии</span
                          >
                        </div>
                        <div class="mb-2">
                          16.08.2022 г. Контрольный осмотр в ГУЗ
                          "СПбКНпЦСВМП(о)", г. Санкт-Петербург
                        </div>
                        <div>
                          НЯ 1 степени тяжести: тошнота после приема препарата в
                          утренние часы, купировалась без симптоматического
                          лечения через 2 недели
                        </div>
                      </div>
                      <div class="nf-other__content-light_svg-second">
                        <svg
                          width="81"
                          height="61"
                          viewBox="0 0 81 61"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="42.5"
                            y="23.5"
                            width="34"
                            height="24"
                            stroke="#1F1F1F"
                          />
                          <rect
                            x="38.5"
                            y="0.5"
                            width="42"
                            height="10"
                            stroke="#1F1F1F"
                          />
                          <path
                            d="M42.5 10.5H76.5V60.5H42.5V10.5Z"
                            stroke="#1F1F1F"
                          />
                          <rect
                            width="31.9825"
                            height="50.1795"
                            transform="matrix(0 -1 1 0 0.838867 61)"
                            fill="#FAEBED"
                          />
                          <rect
                            x="1.33887"
                            y="60.5"
                            width="30.9825"
                            height="49.1795"
                            transform="rotate(-90 1.33887 60.5)"
                            fill="white"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="8.83672"
                            cy="51.1672"
                            r="4.3709"
                            transform="rotate(-90 8.83672 51.1672)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="20.0457"
                            cy="51.1672"
                            r="4.3709"
                            transform="rotate(-90 20.0457 51.1672)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="31.2596"
                            cy="51.1672"
                            r="4.3709"
                            transform="rotate(-90 31.2596 51.1672)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="42.4686"
                            cy="51.1672"
                            r="4.3709"
                            transform="rotate(-90 42.4686 51.1672)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="8.83672"
                            cy="38.8527"
                            r="4.3709"
                            transform="rotate(-90 8.83672 38.8527)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="20.0457"
                            cy="38.8527"
                            r="4.3709"
                            transform="rotate(-90 20.0457 38.8527)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="31.2596"
                            cy="38.8527"
                            r="4.3709"
                            transform="rotate(-90 31.2596 38.8527)"
                            stroke="#1F1F1F"
                          />
                          <circle
                            cx="42.4686"
                            cy="38.8527"
                            r="4.3709"
                            transform="rotate(-90 42.4686 38.8527)"
                            stroke="#1F1F1F"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="nf-other__content-light_text-arrow mb-2 mt-2">
                    <svg
                      width="12"
                      height="23"
                      viewBox="0 0 12 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1L6.75 1ZM5.46967 22.5303C5.76256 22.8232 6.23744 22.8232 6.53033 22.5303L11.3033 17.7574C11.5962 17.4645 11.5962 16.9896 11.3033 16.6967C11.0104 16.4038 10.5355 16.4038 10.2426 16.6967L6 20.9393L1.75736 16.6967C1.46447 16.4038 0.989593 16.4038 0.6967 16.6967C0.403806 16.9896 0.403806 17.4645 0.6967 17.7574L5.46967 22.5303ZM5.25 1L5.25 22L6.75 22L6.75 1L5.25 1Z"
                        fill="#9A3374"
                      ></path>
                    </svg>
                  </div>
                  <div class="nf-other__content-light mb-12">
                    <div class="nf-other__content-light_reverse">
                      <div class="nf-other__content-light_text">
                        <div class="mb-4">
                          <span
                            >09-10.2022 г.— Спустя 5-5,5 мес. после начала
                            терапии</span
                          >
                        </div>
                        <div class="mb-2">
                          НЯ:
                          <ul class="mt-2">
                            <li>
                              тошнота 1-2 степени тяжести (назначена
                              симптоматическая терапия),
                            </li>
                            <li>отсутствие аппетита 1 степени тяжести,</li>
                            <li>
                              алопеция 1 степени тяжести (выпадение волос
                              &lt;50% от нормы).
                            </li>
                          </ul>
                        </div>
                        <div class="mb-2">
                          Запланирована консультация дерматолога.
                        </div>
                        <div class="mb-2">
                          Отмечается побледнение пятна цвета «кофе с молоком».
                        </div>
                        <div class="mb-4">
                          Стабилизация заболевания по данным контрольной МРТ
                          шейно-грудного отдела позвоночника от 23.09.2022 г.
                        </div>
                        <div class="nf-other__content-light_text-flex">
                          <div>
                            <img
                              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/3.jpg"
                              alt=""
                            />
                          </div>
                          <div>
                            <img
                              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          class="nf-other__content-light_text-light-tip mt-4"
                        >
                          Информация о НЯ репортирована с службу медицинской
                          информации компании ООО «АстраЗенека Фармасьютикалз»
                          <br />
                          тел.: +7 (495) 799 56 99 Safety.Russia@astrazeneca.com
                        </div>
                      </div>
                      <div class="nf-other__content-light_svg-second">
                        <svg
                          width="49"
                          height="66"
                          viewBox="0 0 49 66"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="1.33789"
                            y="6.96582"
                            width="47.1622"
                            height="58.5339"
                            rx="3.95946"
                            stroke="#1F1F1F"
                          />
                          <mask id="path-2-inside-1_7098_4874" fill="white">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M29.4694 3.56757H38.0758C38.5684 3.56757 38.9677 3.96689 38.9677 4.45947V13.8243C38.9677 14.3169 38.5684 14.7162 38.0758 14.7162H11.7649C11.2724 14.7162 10.873 14.3169 10.873 13.8243V4.45947C10.873 3.96689 11.2724 3.56757 11.7649 3.56757H20.3716C20.8718 1.51978 22.7187 0 24.9205 0C27.1224 0 28.9693 1.51978 29.4694 3.56757Z"
                            />
                          </mask>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M29.4694 3.56757H38.0758C38.5684 3.56757 38.9677 3.96689 38.9677 4.45947V13.8243C38.9677 14.3169 38.5684 14.7162 38.0758 14.7162H11.7649C11.2724 14.7162 10.873 14.3169 10.873 13.8243V4.45947C10.873 3.96689 11.2724 3.56757 11.7649 3.56757H20.3716C20.8718 1.51978 22.7187 0 24.9205 0C27.1224 0 28.9693 1.51978 29.4694 3.56757Z"
                            fill="white"
                          />
                          <path
                            d="M29.4694 3.56757L28.498 3.80484L28.6843 4.56757H29.4694V3.56757ZM20.3716 3.56757V4.56757H21.1568L21.3431 3.80484L20.3716 3.56757ZM29.4694 4.56757H38.0758V2.56757H29.4694V4.56757ZM38.0758 4.56757C38.0161 4.56757 37.9677 4.51918 37.9677 4.45947H39.9677C39.9677 3.4146 39.1206 2.56757 38.0758 2.56757V4.56757ZM37.9677 4.45947V13.8243H39.9677V4.45947H37.9677ZM37.9677 13.8243C37.9677 13.7646 38.0161 13.7162 38.0758 13.7162V15.7162C39.1206 15.7162 39.9677 14.8692 39.9677 13.8243H37.9677ZM38.0758 13.7162H11.7649V15.7162H38.0758V13.7162ZM11.7649 13.7162C11.8246 13.7162 11.873 13.7646 11.873 13.8243H9.87305C9.87305 14.8692 10.7201 15.7162 11.7649 15.7162V13.7162ZM11.873 13.8243V4.45947H9.87305V13.8243H11.873ZM11.873 4.45947C11.873 4.51917 11.8246 4.56757 11.7649 4.56757V2.56757C10.7201 2.56757 9.87305 3.4146 9.87305 4.45947H11.873ZM11.7649 4.56757H20.3716V2.56757H11.7649V4.56757ZM21.3431 3.80484C21.7364 2.19433 23.1902 1 24.9205 1V-1C22.2471 -1 20.0071 0.845234 19.4002 3.3303L21.3431 3.80484ZM24.9205 1C26.6508 1 28.1046 2.19433 28.498 3.80484L30.4409 3.3303C29.8339 0.845235 27.594 -1 24.9205 -1V1Z"
                            fill="#1F1F1F"
                            mask="url(#path-2-inside-1_7098_4874)"
                          />
                          <path
                            d="M8.5293 37.7944H17.3367L21.7962 48.4971L28.8199 27.2031L33.5023 37.7944H41.3064"
                            stroke="#1F1F1F"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nf-other__content-text_title mb-4">
                  МРТ шейно-грудного отдела позвоночника в динамике
                </div>
                <div class="nf-other__content-text">
                  <picture class="d-none d-md-block">
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-1(tab).jpg
                      "
                      media="(max-width: 1220px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-1.jpg
                      "
                      media="(min-width: 1221px;)"
                    />
                    <img
                      class="mb-8"
                      src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-1.jpg"
                    />
                  </picture>
                  <SimpleCarousel
                    class="d-md-none"
                    :Slides="Slides"
                  ></SimpleCarousel>
                  <picture>
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-2(mob).jpg
                      "
                      media="(max-width: 767px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-2(tab).jpg
                      "
                      media="(max-width: 1220px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-2.jpg
                      "
                      media="(min-width: 1221px;)"
                    />
                    <img
                      class="mb-8"
                      src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-2.jpg"
                    />
                  </picture>
                  <picture>
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-3(mob).jpg
                      "
                      media="(max-width: 767px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-3(tab).jpg
                      "
                      media="(max-width: 1220px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-3.jpg
                      "
                      media="(min-width: 1221px;)"
                    />
                    <img
                      class="mb-8"
                      src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-3.jpg"
                    />
                  </picture>
                  <picture>
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-4(mob).jpg
                      "
                      media="(max-width: 767px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-4(tab).jpg
                      "
                      media="(max-width: 1220px;)"
                    />
                    <source
                      srcset="
                        https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-4.jpg
                      "
                      media="(min-width: 1221px;)"
                    />
                    <img
                      class="mb-4"
                      src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-4.jpg"
                    />
                  </picture>
                  <div class="nf-other__content-light_text-light-tip mb-8">
                    *ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава России, г.
                    Санкт-Петербург <br />
                    **ГУЗ "СПбКНпЦСВМП(о)", г. Санкт-Петербург
                  </div>
                  <div class="nf-other__content-light">
                    <div class="nf-other__content-light_svg">
                      <svg
                        width="146"
                        height="102"
                        viewBox="0 0 96 102"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.498271"
                          y="101.078"
                          width="100.424"
                          height="45.9834"
                          rx="22.9917"
                          transform="rotate(-89.75 0.498271 101.078)"
                          stroke="#1F1F1F"
                        />
                        <circle
                          cx="76.1382"
                          cy="23.5396"
                          r="18.9888"
                          stroke="#1F1F1F"
                        />
                        <circle
                          cx="76.1355"
                          cy="80.149"
                          r="18.9888"
                          transform="rotate(50.4209 76.1355 80.149)"
                          stroke="#1F1F1F"
                        />
                        <path
                          d="M62.7402 36.6332L89.2328 10.1406"
                          stroke="#1F1F1F"
                        />
                        <path
                          d="M0.96582 50.4531L46.4396 50.4531"
                          stroke="#1F1F1F"
                        />
                        <path
                          d="M38.0879 59.2695V76.4382"
                          stroke="#1F1F1F"
                          stroke-linecap="round"
                        />
                        <path
                          d="M56.6494 80.1484L95.627 80.1484"
                          stroke="#1F1F1F"
                        />
                      </svg>
                    </div>
                    <div class="nf-other__content-light_text">
                      <div class="mb-2">
                        <span class="title-big"
                          >В настоящее время терапия продолжается…</span
                        >
                      </div>
                      <div class="mb-1">Прием селуметиниба 70 мг/сут.</div>
                      <div>Других НЯ не зарегистрировано.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nf-other__content-downsect">
            <div class="nf-other__content-downsect_tip">
              <div class="mb-2">
                Безъязычная И.В., врач-детский онколог ГУЗ «СПбКНпЦСВМП(о)»,
                г. Санкт-Петербург, из личного архива 2023 г.
              </div>
              <div>
                <a href="https://az-most.ru/medication/koselugo"
                  >https://az-most.ru/medication/koselugo</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="abbs-container">
      <div class="abbs-title mb-6">
        Список сокращений и условных обозначений:
      </div>
      <div class="abbs-list">
        НФ1 - нейрофиброматоз 1 типа.
        <br /><br />
        ИБС - ишемическая болезнь сердца.
        <br /><br />
        СД - сахарный диабет.
        <br /><br />
        МРТ - магнитно-резонансная томография.
        <br /><br />
        УЗИ - ультразвуковое исследование.
        <br /><br />
        ПЭТ-КТ - позитронно-эмиссионная томография, совмещенная с компьютерной
        томографией.
        <br /><br />
        МСКТ - мультиспиральная компьютерная томография.
        <br /><br />
        ОГК - органы грудной клетки.
        <br /><br />
        к/у - контрастное усиление.
        <br /><br />
        РФП - радиофармацевтический препарат.
        <br /><br />
        ИФТ - иммунофенотипирование.
        <br /><br />
        НЯ - нежелательное явление.
        <br /><br />
        NF1 (neurofibromin 1) - нейрофибромин 1.
        <br /><br />
        NF2 (neurofibromin 2) - нейрофибромин 2.
      </div>
    </div>
    <PopupEnd
      :eventName="'nf-1'"
      :backUrl="{ name: 'NF-1' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import SimpleCarousel from "@/components/pageComponents/SimpleCarousel.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import { mapGetters } from "vuex";
export default {
  name: "NFContent",
  components: {
    SimpleCarousel,
    PopupEnd,
  },
  data: () => ({
    activeTab: 0,
    popup: false,
    Slides: [
      {
        outerHTML: `<img src="${"https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-1(mob-1).jpg"}"/>`,
      },
      {
        outerHTML: `<img src="${"https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/table-1(mob-2).jpg"}"/>`,
      },
    ],
    tabData: [
      {
        question:
          "Какие обследования нужно назначить в первую очередь пациенту с такой клинической картиной?",
        options: [
          "МРТ",
          "УЗИ",
          "ПЭТ/КТ",
          "Биопсию объемного образования в надключичной области",
        ],
        correctAnswerIndex: 3,
        selectedOption: null,
        showAdditionalSection: false,
      },
      {
        question:
          "Показана ли в данном случае терапия препаратом Коселуго<sup>®</sup> (селуметиниб)?",
        options: ["Да", "Нет"],
        correctAnswerIndex: 0,
        selectedOption: null,
        showAdditionalSection: false,
      },
      {
        question: "Какой эффект мы ожидаем получить от терапии?",
        options: [
          "Уменьшение объема опухоли",
          "Купирование симптомов, ассоциированных с ПН",
          "Стабилизация роста опухоли",
        ],
        correctAnswerIndex: 2,
        selectedOption: [],
        showAdditionalSection: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(["user"]),
    innerBorderStyle() {
      if (this.activeTab === 0) {
        return {
          "border-radius": "0px 8px 8px 8px",
        };
      } else if (this.activeTab === 2) {
        return {
          "border-radius": "8px 0px 8px 8px",
        };
      } else {
        return {
          "border-radius": "8px",
        };
      }
    },
  },
  methods: {
    changeTab(index) {
      if (this.user && !Object.keys(this.user).length && !this.$route.query.access) {
        this.popup = true;
      } else {
        this.activeTab = index;
      }
    },
    closePopup() {
      this.popup = false;
    },
    submitAnswer() {
      const tab = this.tabData[this.activeTab];

      if (tab.selectedOption === tab.correctAnswerIndex) {
        tab.showAdditionalSection = true;
      } else {
        tab.showAdditionalSection = true;
      }
    },
    scrollToTabTopAndChangeTab(index) {
      this.changeTab(index);
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        setTimeout(() => {
          const tabBtn = document.querySelector(
            `.nf-other__tabs-btn:nth-child(${index + 1})`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
        }, 100); // Задержка для обеспечения корректной смены таба перед прокруткой
      }
    },
    getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
  },
};
</script>

<style lang="scss">
.nf-other {
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: 98px;
    @media screen and(max-width: 1199px) {
    }
    @media screen and(max-width: 767px) {
      max-height: 120px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;
      padding: 16px;
      width: 251px;
      height: 72px;
      background-color: #f8f8f8;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      &_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and(max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &.active {
        border: none;
        height: 98px;
        border-radius: 8px 8px 0px 0px;
        background-color: #f4eff4;
        @media screen and(max-width: 767px) {
          height: 120px;
        }
      }
      @media screen and(max-width: 1199px) {
        max-width: 200px;
        font-size: 14px;
        padding: 16px;
      }
      @media screen and(max-width: 767px) {
        height: 109px;
        font-size: 12px;
        line-height: 16px;
        padding: 8px;
        font-weight: 500;
      }
    }
    &-icon {
      margin-right: 16px;
      @media screen and(max-width: 767px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
  &__content {
    padding: 24px 16px;
    background-color: #f4eff4;
    &-sect {
      position: relative;
      border-left: 2px solid #9a3374;
      padding-left: 30px;
      padding-top: 1px;
      padding-bottom: 14px;
      margin-bottom: 20px;
      &::before {
        position: absolute;
        top: 0;
        left: -1px;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
        background: #9a3374;
        content: "";
      }
      &.undoted {
        border-left: none;
        padding-left: 0;
        &::before {
          display: none;
        }
      }
    }
    &-date {
      margin-top: -16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #9a3374;
    }
    &-text {
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #3c4242;
      &_title {
        font-size: 18px;
      }
      span {
        font-weight: 500;
        color: #9a3374;
      }
      .red-arrow_up {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &::before {
          margin-top: 5px;
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 8px;
          content: "";
          flex-shrink: 0;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 7.5L8 2.5L13 7.5' stroke='%23CB4050' stroke-linecap='square'/%3E%3Cpath d='M8 3.5L8 13.5' stroke='%23CB4050' stroke-linecap='square'/%3E%3C/svg%3E%0A");
        }
      }
    }
    &-light {
      display: flex;
      align-items: flex-start;
      padding: 24px;
      background-color: #ffffff;
      @media screen and(max-width: 767px) {
        flex-direction: column;
      }
      &_reverse {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .nf-other__content-light_text {
          max-width: 619px;
        }
        @media screen and(max-width: 767px) {
          flex-direction: column-reverse;
          align-items: flex-start;
        }
      }
      &_svg {
        margin-right: 16px;
        @media screen and(max-width: 767px) {
          margin-right: 0px;
          margin-bottom: 16px;
        }
        &-second {
          // width: 110px;
          @media screen and(max-width: 767px) {
            margin-bottom: 16px;
          }
        }
      }
      &_text {
        &-flex {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 24px;
          @media screen and(max-width: 767px) {
            flex-direction: column;
          }
        }
        &-arrow {
          display: flex;
          justify-content: center;
        }
        &-light-descr {
          margin-top: 16px;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        &-light-tip {
          font-size: 10px;
          line-height: 13px;
          color: #3c4242;
        }
      }
    }
    &-test {
      padding: 24px;
      background-color: #ffffff;
      &_head {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #9a3374;
      }
      &_svg {
        margin-right: 16px;
      }

      &_btn {
        margin-top: 24px;
        cursor: pointer;
        width: 107px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 18px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        border-radius: 999px;
        background-color: #830051;
        color: #ffffff;
        transition: 0.2s all linear;
        &:hover {
          background-color: #9a3374;
        }
      }
    }
    &-downsect {
      &_btn {
        max-width: 247px;
        &-container {
          display: flex;
          justify-content: flex-end;
        }
        @media screen and(max-width: 767px) {
          max-width: 100%;
          width: 100%;
        }
      }
      &_tip {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #3c4242;
        a {
          color: #3c4242 !important;
          text-decoration: none !important;
        }
      }
    }
  }
  .title-big {
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    @media screen and(max-width: 1199px) {
      font-size: 23px;
      line-height: 29px;
    }
    @media screen and(max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  .abbs {
    &-container {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #3c4242;
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      line-height: 29px;
      color: #830051;
    }
  }
}
</style>
