<template>
  <div class="agus-buttons__block">
    <div
      class="d-flex direction-md-row direction-column flex-gap-16 mt-6"
      style="height: 100%"
    >
      <div class="d-flex direction-column justify-center flex-gap-16">
        <!-- Кнопка Заболеваемость -->
        <a
          class="button d-inline-flex px-8 new-materials_btn"
          :class="{ 'is-active': activeImage === 'one' }"
          @click.prevent="setActiveImage('one')"
          href="#"
          style="border: 1px solid #f36c3e"
        >
          <span class="in-c">Заболеваемость</span>
        </a>

        <!-- Кнопка Распространенность -->
        <a
          class="button d-inline-flex px-8 new-materials_btn"
          :class="{ 'is-active': activeImage === 'two' }"
          @click.prevent="setActiveImage('two')"
          href="#"
          style="border: 1px solid #418886"
        >
          <span class="in-c">Распространенность</span>
        </a>
      </div>

      <!-- Контейнер с изображениями -->
      <div class="agus-images__container">
        <picture class="agus-image__one" v-if="activeImage === 'one'">
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop1(mob).png
            "
            media="(max-width: 767px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop1(tab).png
            "
            media="(max-width: 1199px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop1.png
            "
            media="(min-width: 1299px)"
          />
          <img
            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop1.png"
            alt="Заболеваемость"
          />
        </picture>

        <picture class="agus-image__two" v-if="activeImage === 'two'">
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop2(mob).png
            "
            media="(max-width: 767px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop2(tab).png
            "
            media="(max-width: 1199px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop2.png
            "
            media="(min-width: 1299px)"
          />
          <img
            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/loop2.png"
            alt="Распространенность"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PNGButtons",
  data() {
    return {
      activeImage: "one", // По умолчанию показываем первое изображение
    };
  },
  methods: {
    setActiveImage(image) {
      this.activeImage = image;
    },
  },
};
</script>

<style lang="scss" scoped>
.agus-buttons__block {
  height: 114px;
  @media screen and (max-width: 767px) {
    height: auto;
  }
}

.button {
  text-align: center;
  background-color: transparent; /* Прозрачный фон по умолчанию */
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.button:nth-child(1) {
  color: #f36c3e;
}

.button:nth-child(2) {
  color: #418886;
}

.button.is-active {
  color: #fff; /* Белый текст для активной кнопки */
}

/* Стили для активной кнопки "Заболеваемость" */
.button.is-active:nth-child(1) {
  background-color: #f36c3e; /* Оранжевый фон для "Заболеваемость" */
}

/* Стили для активной кнопки "Распространенность" */
.button.is-active:nth-child(2) {
  background-color: #418886; /* Цвет фона для "Распространенность" */
}

/* Добавим эффекты при наведении */
.button:hover {
  opacity: 0.8;
}

.agus-image__one img,
.agus-image__two img {
  max-height: 112px;
  height: 100%;
}
</style>
