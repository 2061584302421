<template>
  <div class="soundboard">
    <div class="full-screen mb-6">
      <div class="full-screen__trigger d-md-none">
        <svg
          width="40"
          height="40"
          viewbox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="8" fill="#830051"></rect>
          <path
            d="M23 11H29V17"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M17 29H11V23"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M29 11L22 18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M11 29L18 22"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
      <picture>
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4(mob).jpg
          "
          media="(max-width: 767px)"
        />
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4(tab).jpg
          "
          media="(max-width: 1199px)"
        />
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4.jpg
          "
          media="(min-width: 1299px)"
        />
        <img
          class="soundboard__img"
          src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4.jpg"
        />
      </picture>
      <div
        class="soundboard__btn first"
        @click="
          soundPlay(
            'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Лихорадка ≥ 38°C.mp3'
          )
        "
      ></div>
      <div
        class="soundboard__btn second"
        @click="
          soundPlay(
            'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Раздувание крыльев носа.mp3'
          )
        "
      ></div>
      <div
        class="soundboard__btn third"
        @click="
          soundPlay(
            'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Учащенное дыхание, свистящие хрипы.mp3'
          )
        "
      ></div>
      <div
        class="soundboard__btn fourth"
        @click="
          soundPlay(
            'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Ретракция.mp3'
          )
        "
      ></div>
      <!-- <div class="soundboard__btn fifth"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "soundImage",
  data: () => ({
    currentAudio: null,
  }),
  methods: {
    soundPlay(sound) {
      if (sound) {
        if (this.currentAudio) {
          this.currentAudio.pause();
        }
        let audio = new Audio(sound);
        audio.play();
        this.currentAudio = audio;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.soundboard {
  &__img {
    position: relative;
  }
  &__btn {
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 97px;
    height: 93px;
    @media screen and (max-width: 1023px) {
      width: 75px;
      height: 70px;
    }
    @media screen and (max-width: 767px) {
      width: 88px;
      height: 45px;
    }
  }
  &__btn.first {
    top: 20px;
    left: 30px;
    @media screen and (max-width: 1023px) {
      top: 25px;
      left: 25px;
    }
    @media screen and (max-width: 767px) {
      top: 20px;
      left: 10px;
    }
  }
  &__btn.second {
    top: 165px;
    left: 30px;
    @media screen and (max-width: 1023px) {
      top: 145px;
      left: 25px;
    }
    @media screen and (max-width: 767px) {
      left: 10px;
      top: 112px;
    }
  }
  &__btn.third {
    bottom: 75px;
    left: 30px;
    @media screen and (max-width: 1023px) {
      bottom: 70px;
      left: 25px;
    }
    @media screen and (max-width: 767px) {
      bottom: 100px;
      left: 10px;
    }
  }
  &__btn.fourth {
    top: 20px;
    right: 130px;
    @media screen and (max-width: 1023px) {
      top: 25px;
      right: 100px;
    }
    @media screen and (max-width: 767px) {
        top: 20px;
        right: 40px;
    }
  }
}
</style>
