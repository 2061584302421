<template>
  <div class="gff-other">
    <div class="gff-other__container mb-12">
      <div class="gff-other__tabs">
        <div
          class="gff-other__tabs-btn"
          @click="changeTab(0)"
          :class="{ active: activeTab === 0 }"
        >
          <div class="gff-other__tabs-btn_wrap">Новорожденные</div>
        </div>
        <div
          class="gff-other__tabs-btn"
          @click="changeTab(1)"
          :class="{ active: activeTab === 1 }"
        >
          <div class="gff-other__tabs-btn_wrap">Дети и подростки</div>
        </div>
        <div
          class="gff-other__tabs-btn"
          @click="changeTab(2)"
          :class="{ active: activeTab === 2 }"
        >
          <div class="gff-other__tabs-btn_wrap">Взрослые</div>
        </div>
      </div>
      <div class="gff-other__content" :style="innerBorderStyle">
        <div class="gff-other__content-block" v-show="activeTab === 0">
          <div class="gff-other__main-top">
            <b
              >Заболевание особенно опасно при дебюте в раннем детском
              возрасте<sup>3</sup>.</b
            >
          </div>
          <div class="gff-other__main-middle">
            <div class="gff-other__main-middle_item">
              <img
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/ci_tab-1.png"
                alt=""
              />
              <div>
                новорожденных и детей грудного возраста с ГФФ умирают на первом
                году жизни
              </div>
            </div>
          </div>
        </div>
        <div class="gff-other__content-block" v-show="activeTab === 1">
          <div class="gff-other__main-top">
            <b>У детей и подростков</b> симптомы ГФФ
            <b>вызывают задержку роста и развития</b>, причиняют боль,
            ограничивают ежедневную активность,
            <b>снижая качество жизни<sup>3</sup></b
            >.
          </div>
          <div class="gff-other__main-middle">
            <div class="gff-other__main-middle_item">
              <img
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/ci_tab-2.png"
                alt=""
              />
              <div>ежедневно испытывают боль связанную с проявлениями ГФФ</div>
            </div>
            <div class="gff-other__main-middle_item">
              <img
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/ci_tab-3.png"
                alt=""
              />
              <div>
                вынуждены использовать инвалидную коляску для передвижения
              </div>
            </div>
          </div>
          <div class="gff-other__main-bottom">
            Снижение костной массы в детстве приводит к более высокому риску
            переломов во взрослом возрасте.
          </div>
        </div>
        <div
          class="gff-other__content-block third-block"
          v-show="activeTab === 2"
        >
          <div class="gff-other__main-top">
            <b>
              У взрослых пациентов, ГФФ может приводить к тяжелым,
              инвалидизирующим последствиям, что негативно влияет на
              работоспособность и образ жизни пациентов.
            </b>
            <br /><br />
            По данным международного регистра<sup>4</sup>:
          </div>
          <div class="gff-other__main-middle">
            <div class="gff-other__main-middle_item">
              <img
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/ci_tab-4.png"
                alt=""
              />
              <div>
                вынуждены использовать трость и другие вспомогательные
                устройства для передвижения по дому или улице
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GffTabs",
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    innerBorderStyle() {
      if (this.activeTab === 0) {
        return {
          "border-radius": "0px 8px 8px 8px",
        };
      } else if (this.activeTab === 2) {
        return {
          "border-radius": "8px 0px 8px 8px",
        };
      } else {
        return {
          "border-radius": "8px",
        };
      }
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    scrollToTabTopAndChangeTab(index) {
      this.changeTab(index);
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        setTimeout(() => {
          const tabBtn = document.querySelector(
            `.gff-other__tabs-btn:nth-child(${index + 1})`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
        }, 100); // Задержка для обеспечения корректной смены таба перед прокруткой
      }
    },
    getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
  },
};
</script>

<style lang="scss">
.gff-other {
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: 108px;
    @media screen and(max-width: 1199px) {
    }
    @media screen and(max-width: 767px) {
      max-height: 120px;
      gap: 8px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      padding: 14px 24px;
      width: 100%;
      height: 72px;
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
      &_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 38px;
        word-break: break-all;
        @media screen and(max-width: 767px) {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
      &.active {
        border: none;
        height: 88px;
        border-radius: 8px 8px 0px 0px;
        background-color: #ffe41433;
        @media screen and(max-width: 767px) {
          
        }
      }
      @media screen and(max-width: 1199px) {
        max-width: 186px;
        height: 72px;
      }
      @media screen and(max-width: 767px) {
        font-weight: 500;
        padding: 8px 16px;
      }
    }
  }
  &__content {
    padding: 24px;
    background-color: #ffe41433;
    @media screen and(max-width: 1199px) {
      padding: 24px 16px;
    }
    &-block {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      align-items: center;
      @media screen and(max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__main {
    &-middle {
      display: flex;
      flex-direction: row;
      gap: 24px;
      @media screen and(max-width: 1199px) {
        flex-direction: column;
      }
      &_item {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        img {
          flex-shrink: 0;
          width: 134px;
          height: 134px;
          @media screen and (max-width: 767px) {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }
}
</style>
