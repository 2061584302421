<template>
  <div>
    <div class="strenzik">
      <div class="strenzik__title">
        Расчет потребности в ЛП Стрензик® (асфотаза альфа)
      </div>
      <div class="strenzik__row">
        <div class="strenzik__block">
          <div class="strenzik__block-title">
            2 мг/кг, введение 3 раза в неделю (рекомендуемая доза 6 мг/кг в
            неделю)
          </div>
          <div class="strenzik__form">
            <input
              type="number"
              v-model.number="kg_3"
              class="strenzik__input"
              placeholder="кг"
              :class="{ 'strenzik__input-error': error_3 }"
            />
            <div class="strenzik__input strenzik__input_disabled">
              Доза 2 мг/кг
            </div>
            <div class="strenzik__button button button_pink d-inline-flex px-4" v-on:click="calc3">Рассчитать</div>
          </div>
          <div class="strenzik__result strenzik__result_yellow">
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Необходимая доза для введения, мл
              </div>
              <div class="strenzik__result-item-value">{{ result_3.doza }} мг</div>
            </div>
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Объем для введения, мл
              </div>
              <div class="strenzik__result-item-value">{{ result_3.size }} мл</div>
            </div>
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Используемый флакон, форма выпуска, мг/мл
              </div>
              <div class="strenzik__result-item-value">{{ result_3.flakon }}</div>
            </div>
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Количество флаконов на одно введение, шт
              </div>
              <div class="strenzik__result-item-value">{{ result_3.quantity }} шт</div>
            </div>
          </div>
        </div>
        <div class="strenzik__block">
          <div class="strenzik__block-title">
            1 мг/кг, введение 6 раз в неделю (рекомендуемая доза 6 мг/кг в
            неделю)
          </div>
          <div class="strenzik__form">
            <input
              type="number"
              v-model.number="kg_6"
              class="strenzik__input"
              placeholder="кг"
              :class="{ 'strenzik__input-error': error_6 }"
            />
            <div class="strenzik__input strenzik__input_disabled">
              Доза 1 мг/кг
            </div>
            <div class="strenzik__button button button_pink d-inline-flex px-4" v-on:click="calc6">Рассчитать</div>
          </div>
          <div class="strenzik__result strenzik__result_green">
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Необходимая доза для введения, мл
              </div>
              <div class="strenzik__result-item-value">{{ result_6.doza }} мг</div>
            </div>
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Объем для введения, мл
              </div>
              <div class="strenzik__result-item-value">{{ result_6.size }} мл</div>
            </div>
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Используемый флакон, форма выпуска, мг/мл
              </div>
              <div class="strenzik__result-item-value">{{ result_6.flakon }}</div>
            </div>
            <div class="strenzik__result-item">
              <div class="strenzik__result-item-name">
                Количество флаконов на одно введение, шт
              </div>
              <div class="strenzik__result-item-value">{{ result_6.quantity }} шт</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Strenzik",
  data: () => ({
    error_3: false,
    error_6: false,
    calc_3_data: {
      0: {doza: 0, size: 0, flakon: "0 мг/0 мл", quantity: 0},
      3: {doza: 6, size: 0.15, flakon: "28 мг/0,7 мл", quantity: 1},
      4: {doza: 8, size: 0.2, flakon: "28 мг/0,7 мл", quantity: 1},
      5: {doza: 10, size: 0.25, flakon: "28 мг/0,7 мл", quantity: 1},
      6: {doza: 12, size: 0.3, flakon: "28 мг/0,7 мл", quantity: 1},
      7: {doza: 14, size: 0.35, flakon: "28 мг/0,7 мл", quantity: 1},
      8: {doza: 16, size: 0.4, flakon: "28 мг/0,7 мл", quantity: 1},
      9: {doza: 18, size: 0.45, flakon: "28 мг/0,7 мл", quantity: 1},
      10: {doza: 20, size: 0.50, flakon: "28 мг/0,7 мл", quantity: 1},
      11: {doza: 22, size: 0.55, flakon: "28 мг/0,7 мл", quantity: 1},
      12: {doza: 24, size: 0.6, flakon: "28 мг/0,7 мл", quantity: 1},
      13: {doza: 26, size: 0.65, flakon: "28 мг/0,7 мл", quantity: 1},
      14: {doza: 28, size: 0.7, flakon: "28 мг/0,7 мл", quantity: 1},
      15: {doza: 30, size: 0.75, flakon: "40 мг/1,0 мл", quantity: 1},
      16: {doza: 32, size: 0.80, flakon: "40 мг/1,0 мл", quantity: 1},
      17: {doza: 34, size: 0.85, flakon: "40 мг/1,0 мл", quantity: 1},
      18: {doza: 36, size: 0.9, flakon: "40 мг/1,0 мл", quantity: 1},
      19: {doza: 38, size: 0.95, flakon: "40 мг/1,0 мл", quantity: 1},
      20: {doza: 40, size: 1.0, flakon: "40 мг/1,0 мл", quantity: 1},
      25: {doza: 50, size: 0.5, flakon: "80 мг/0,8 мл", quantity: 1},
      30: {doza: 60, size: 0.6, flakon: "80 мг/0,8 мл", quantity: 1},
      35: {doza: 70, size: 0.7, flakon: "80 мг/0,8 мл", quantity: 1},
      40: {doza: 80, size: 0.8, flakon: "80 мг/0,8 мл", quantity: 1},
    },
    calc_6_data: {
      0: {doza: 0, size: 0.0, flakon: "0 мг/0 мл", quantity: 0},
      6: {doza: 6, size: 0.15, flakon: "28 мг/0,7 мл", quantity: 1},
      7: {doza: 7, size: 0.18, flakon: "28 мг/0,7 мл", quantity: 1},
      8: {doza: 8, size: 0.2, flakon: "28 мг/0,7 мл", quantity: 1},
      9: {doza: 9, size: 0.23, flakon: "28 мг/0,7 мл", quantity: 1},
      10: {doza: 10, size: 0.25, flakon: "28 мг/0,7 мл", quantity: 1},
      11: {doza: 11, size: 0.28, flakon: "28 мг/0,7 мл", quantity: 1},
      12: {doza: 12, size: 0.3, flakon: "28 мг/0,7 мл", quantity: 1},
      13: {doza: 13, size: 0.33, flakon: "28 мг/0,7 мл", quantity: 1},
      14: {doza: 14, size: 0.35, flakon: "28 мг/0,7 мл", quantity: 1},
      15: {doza: 15, size: 0.38, flakon: "28 мг/0,7 мл", quantity: 1},
      16: {doza: 16, size: 0.4, flakon: "28 мг/0,7 мл", quantity: 1},
      17: {doza: 17, size: 0.43, flakon: "28 мг/0,7 мл", quantity: 1},
      18: {doza: 18, size: 0.45, flakon: "28 мг/0,7 мл", quantity: 1},
      19: {doza: 19, size: 0.48, flakon: "28 мг/0,7 мл", quantity: 1},
      20: {doza: 20, size: 0.5, flakon: "28 мг/0,7 мл", quantity: 1},
      25: {doza: 25, size: 0.63, flakon: "28 мг/0,7 мл", quantity: 1},
      30: {doza: 30, size: 0.75, flakon: "40 мг/1,0 мл", quantity: 1},
      35: {doza: 35, size: 0.88, flakon: "40 мг/1,0 мл", quantity: 1},
      40: {doza: 40, size: 1.0, flakon: "40 мг/1,0 мл", quantity: 1},
      50: {doza: 50, size: 0.5, flakon: "80 мг/0,8 мл", quantity: 1},
      60: {doza: 60, size: 0.6, flakon: "80 мг/0,8 мл", quantity: 1},
      70: {doza: 70, size: 0.7, flakon: "80 мг/0,8 мл", quantity: 1},
      80: {doza: 80, size: 0.8, flakon: "80 мг/0,8 мл", quantity: 1},
      90: {doza: 90, size: 0.9, flakon: "80 мг/0,8 мл", quantity: 2},
      100: {doza: 100, size: 1, flakon: "80 мг/0,8 мл", quantity: 2},
    },
    result_3: {doza: 0, size: 0, flakon: "0 мг/0 мл", quantity: 0},
    result_6: {doza: 0, size: 0, flakon: "0 мг/0 мл", quantity: 0},
    kg_3: 0,
    kg_6: 0,

  }),
  methods: {
    calc3() {
      this.error_3 = false;
      this.result_3 = this.calc_3_data[this.kg_3];
      if (this.kg_3 > 40 || this.kg_3 < 3) {
        this.error_3 = true;
        this.result_3 = this.calc_3_data[0];
      }
      if (this.result_3 == undefined) {
        this.result_3 = this.calc_3_data[Math.round(this.kg_3 / 5) * 5]
        if (this.result_3 == undefined) {
          this.error_3 = true;
          this.result_3 = this.calc_3_data[0];
        }
      }
    },
    calc6() {
      this.error_6 = false;
      this.result_6 = this.calc_6_data[this.kg_6];
      if (this.kg_6 > 100 || this.kg_6 < 6) {
        this.error_6 = true;
        this.result_6 = this.calc_6_data[0];
      }
      if (this.result_6 == undefined) {
        let calc_key;
        if (this.kg_6 < 40) {
          calc_key = Math.round(this.kg_6 / 5) * 5;
        } else {
          calc_key = Math.round(this.kg_6 / 10) * 10;
        }
        this.result_6 = this.calc_6_data[calc_key]
        if (this.result_6 == undefined) {
          this.error_6 = true;
          this.result_6 = this.calc_6_data[0];
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.strenzik {
  padding: 24px 16px;
  background-color: #165d5b;

  &__button {
     @media screen and (max-width: 767px) {
      grid-column-end: 3;
      grid-column-start: 1;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
    }
  }

  &__block {
    padding: 16px;
    background-color: #fff;
    width: calc(50% - 24px);

    @media screen and (max-width: 1219px) {
      width: 100%;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      margin-bottom: 16px;
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 99px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #3c4242;
    display: flex;
    justify-content: center;
    align-items: center;

    &-error {
      color: rgb(214, 3, 3);
      border-color: rgb(214, 3, 3);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &_disabled {
      font-size: 14px;
      background: #f8f8f8;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 24px;

     @media screen and (max-width: 767px) {
       grid-template-columns: repeat(2, 1fr);
    }
  }

  &__result {
    @media screen and (max-width: 1219px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }


    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 16px;

      @media screen and (max-width: 1219px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-block: 0;
      }

      &-name {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #3c4242;
        padding: 8px;
        width: 69%;
        border: 1px solid #c7e4b9;

        @media screen and (max-width: 1219px) {
          display: flex;
          align-items: center;
          width: 50%;
        }
      }

      &-value {
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #1f1f1f;
        padding: 8px;
        display: flex;
        width: 31%;
        align-items: center;
        border: 1px solid #c7e4b9;

        @media screen and (max-width: 1219px) {
          width: 50%;
        }
      }
    }

    &_yellow {
      .strenzik__result-item-name {
        background-color: #f3f7cc;
      }
    }

    &_green {
      .strenzik__result-item-name {
        background-color: #c7e4b9;
      }
    }
  }
}
</style>