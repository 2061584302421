<template>
  <div class="calculator">
    <div class="calculator__body">
      <div class="calculator__title">
        Калькулятор расчета необходимого количества ЭНХЕРТУ
      </div>
      <div class="calculator__fields">
        <TextField
          v-model="weight"
          :label="'Масса тела, кг'"
          :placeholder="'кг'"
          :rules="[weightError || 'Введите вес']"
        />
        <Select
          :rules="[Rules.isRequired(dose.length > 0, 'Выберите дозировку')]"
          v-model="dose"
          :label="'&nbsp;'"
          :items="dose_list"
          :placeholder="'Доза мл/кг'"
        />
      </div>
      <div class="button button_pink d-inline-flex mt-4" @click="calculate">
        Рассчитать
      </div>
    </div>

    <div class="calculator__result">
      <div class="calculator__result-item">
        <div class="calculator__result-item-title">ЭНХЕРТУ, мг</div>
        <div class="calculator__result-item-value">{{ result.drug }}</div>
      </div>
      <div class="calculator__result-item">
        <div class="calculator__result-item-title">Флаконы, шт</div>
        <div class="calculator__result-item-value">{{ result.amount }}</div>
      </div>
      <div class="calculator__result-item">
        <div class="calculator__result-item-title">Концентрат, мл</div>
        <div class="calculator__result-item-value">
          {{ result.concentrate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/form-elements/Select.vue";
import TextField from "@/components/form-elements/TextField.vue";
import { mapGetters } from "vuex";
import { bus } from "@/main";
export default {
  name: "EnhertuCalculator",
  components: {
    TextField,
    Select,
  },
  data: () => ({
    weightError: true,
    doseError: true,
    weight: null,
    dose: [],
    dose_list: [
      { label: "6,4 мг/кг (только для РЖ)", value: 6.4 },
      { label: "5,4 мл/кг", value: 5.4 },
      { label: "4,4 мл/кг", value: 4.4 },
      { label: "3,2 мл/кг", value: 3.2 },
    ],
    result: {
      drug: null,
      amount: null,
      concentrate: null,
    },
  }),
  computed: {
    ...mapGetters(["Rules"]),
  },
  methods: {
    calculate() {
      let res = [];
      this.$set(this, "weightError", !!this.weight);
      this.doseError = this.dose.length > 0;
      setTimeout(() => {
        bus.$emit("validate", this._uid);
      
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        let drug = (this.weight * this.dose).toFixed(2);
        this.result.drug = drug;
        this.result.amount = Math.ceil(drug / 100.0);
        this.result.concentrate = (drug / 20.0).toFixed(2);
      }
      bus.data.result = [];
      }, 100);
    },
  },
  watch: {
    weight() {
      this.$set(this, "weightError", !!this.weight);
      this.result = {
        drug: null,
        amount: null,
        concentrate: null,
      };
    },
    dose() {
      setTimeout(() => {
        this.doseError = this.dose.length > 0;
        if (this.doseError) {
          bus.$emit("validate", this._uid);
        }
        this.result = {
          drug: null,
          amount: null,
          concentrate: null,
        };
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculator {
  margin-bottom: 32px;
  &__body {
    border: 1px solid #d2d2d2;
    background: #f8f8f8;
    padding: 32px;
    @media screen and (max-width: 767px) {
      padding: 16px 8px;
    }
  }

  &__title {
    color: #634075;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;
  }

  &__fields {
    display: grid;
    grid-template-columns: 200px 200px;
    column-gap: 32px;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }
  }

  &__result {
    padding: 32px;
    background: #634075;
    display: grid;
    grid-template-columns: repeat(3, 130px);
    column-gap: 32px;

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
      padding: 16px 8px;
    }

    &-item {
      &-title {
        color: #fff;
        text-align: center;
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          padding: 0 11%;
        }
      }

      &-value {
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        min-height: 48px;
        padding-bottom: 4px;
        border-bottom: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>