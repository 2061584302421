<template>
  <div class="png__m">
    <div class="png__m-grid">
      <div
        class="png__m-grid_item"
        v-for="(item, index) in items"
        :key="index"
        @click="openModal(index)"
      >
        <div class="png__m-grid_item-text">
          <div class="png__m-grid_item-title">{{ item.title }}</div>
          <!-- <div class="png__m-grid_item-descr">{{ item.description }}</div> -->
        </div>
        <div class="png__m-grid_item-svg">
          <svg
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.0454102" width="28" height="28" rx="14" fill="#830051" />
            <path
              d="M14.5 9.04541L19.5 14.0454L14.5 19.0454"
              stroke="#E6CCDC"
              stroke-linecap="square"
            />
            <path
              d="M18.5 14.0454L8.5 14.0454"
              stroke="#E6CCDC"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="selectedItemIndex !== null"
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal">
        <div class="modal-header">
          <div class="modal-header_title">
            {{ modalData[selectedItemIndex].title }}
          </div>
          <div class="close" @click="closeModal">
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 7L25 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 7L7 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-block">
            <div
              class="modal-block-text"
              v-for="(block, index) in modalData[selectedItemIndex].content"
              :key="index"
            >
              <div class="modal-block-text__inner" v-html="block.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PNGModalBlock",
  data() {
    return {
      items: [
        {
          title: "Это НЕ пароксизмальное заболевание",
        },
        {
          title: "Это заболевание НЕ связано с ночным временем суток",
        },
        {
          title: "Это НЕ форма гемолитической анемии",
        },
        {
          title:
            "НЕ у всех пациентов при первичном обследовании имеется гемоглобинурия",
        },
      ],
      modalData: [
        {
          title: "Это НЕ пароксизмальное заболевание",
          content: [
            {
              text: "Пароксизм в широком смысле определяется как внезапное появление симптомов заболевания, особенно с рецидивирующим характером. В контексте ПНГ пароксизм относится к внезапному выраженному увеличению скорости внутрисосудистого гемолиза. Однако при ПНГ патологическое прогрессирование гемолиза продолжается даже при отсутствии симптомов.",
            },
          ],
        },
        {
          title: "Это заболевание НЕ связано с ночным временем суток",
          content: [
            {
              text: "Гемолиз при ПНГ слабо выражен и носит постоянный характер, независимо от времени суток.<br><br>Исторически термин «ночной» связан с тем, что в учебниках приводились примеры пациентов с темным цветом мочи после пробуждения.",
            },
          ],
        },
        {
          title: "Это НЕ форма гемолитической анемии",
          content: [
            {
              text: "<strong>Гемоглобинурия</strong> — это наличие гемоглобина в моче, которое может возникнуть при недостаточных функциональных возможностях почек, когда количество поступающего в почки свободного гемоглобина превышает способность почек его фильтровать. При этом гемоглобин выводится с мочой, иногда в достаточно больших количествах, что приводит к окрашиванию мочи в черный цвет.<br><br>Хотя гемоглобинурия наблюдается у 62% пациентов с ПНГ, у некоторых пациентов течение заболевания может не сопровождаться гемоглобинурией.",
            },
          ],
        },
        {
          title:
            "НЕ у всех пациентов при первичном обследовании имеется гемоглобинурия",
          content: [
            {
              text: "ПНГ долгое время считали формой гемолитической анемии, но сегодня это заболевание более точно определяется как форма хронического, неконтролируемого внутрисосудистого гемолиза, опосредованного комплементом, с разрушительными системными последствиями, приводящими к тяжёлым осложнениям и высокому риску преждевременной смерти.<br><br>Хотя анемия может быть проявлением гемолиза, было показано, что патологические проявления комплемент-опосредованного внутрисосудистого гемолиза при ПНГ слабо зависят от выраженности анемии. ПНГ возникает вследствие приобретённой мутации гена PIG-A в гемопоэтических стволовых клетках, которая присутствует во всех последующих клеточных линиях.",
            },
          ],
        },
      ],
      selectedItemIndex: null,
    };
  },
  methods: {
    openModal(index) {
      this.selectedItemIndex = index;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.selectedItemIndex = null;
      document.body.style.overflow = "";
    },
  },
};
</script>

<style lang="scss">
.png__m {
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
    &_item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      border: 1px solid #830051;
      transition: all 0.2s linear;
      &:hover {
        background-color: #e6ccdc;
      }
      @media screen and (max-width: 1199px) {
        gap: 0;
        min-height: 175px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: row;
        justify-content: space-between;
        min-height: auto;
        gap: 16px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      &-title {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &-descr {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
      &-svg {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.modal {
  background: #f8f8f8;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  @media screen and (max-width: 1199px) {
    width: 80%;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    overflow: hidden;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #830051;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    &_title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
    }
  }
  .close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  &-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 80vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-block {
    display: flex;
    flex-direction: row;
    gap: 16px;
    &-text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__inner {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
</style>
