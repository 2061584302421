<template>
  <div class="gff__m">
    <div class="gff__m-grid">
      <div
        class="gff__m-grid_item"
        v-for="(item, index) in items"
        :key="index"
        @click="openModal(index)"
      >
        <div class="gff__m-grid_item-text">
          <div class="gff__m-grid_item-title">{{ item.title }}</div>
          <div class="gff__m-grid_item-descr">{{ item.description }}</div>
        </div>
        <div class="gff__m-grid_item-svg">
          <svg
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.0454102" width="28" height="28" rx="14" fill="#830051" />
            <path
              d="M14.5 9.04541L19.5 14.0454L14.5 19.0454"
              stroke="#E6CCDC"
              stroke-linecap="square"
            />
            <path
              d="M18.5 14.0454L8.5 14.0454"
              stroke="#E6CCDC"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="selectedItemIndex !== null"
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal">
        <div class="modal-header">
          <div class="modal-header_title">
            {{ modalData[selectedItemIndex].title }}
          </div>
          <div class="close" @click="closeModal">
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 7L25 25"
                stroke="#3C4242"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 7L7 25"
                stroke="#3C4242"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
        <div class="modal-content">
          <div
            v-for="(block, index) in modalData[selectedItemIndex].content"
            :key="index"
            class="modal-block"
          >
            <div class="modal-block-image">
              <img :src="block.image" alt="Block Image" class="" />
            </div>
            <div class="modal-block-text">
              <div class="modal-block-text__title">{{ block.heading }}</div>
              <ul>
                <li v-for="(text, idx) in block.text" :key="idx">{{ text }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GffModalBlock",
  data() {
    return {
      items: [
        {
          title: "Перинатально",
          description: "(развитие симптомов внутриутробно или при рождении)",
        },
        {
          title: "У младенцев",
          description: "(до 6-месячного возраста)",
        },
        {
          title: "У детей и подростков",
          description: "(6 мес − 18 лет)",
        },
        {
          title: "У взрослых",
          description: "(старше 18 лет)",
        },
      ],
      modalData: [
        {
          title: "Перинатально",
          content: [
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-1.png", // Или путь к локальному SVG
              heading: "Скелетные симптомы:",
              text: [
                "гипоминерализация скелета с укорочением конечностей и тяжелыми деформациями ребер, позвоночника, грудной клетки;",
                "переломы, возникшие внутриутробно;",
                "мембранозный череп;",
                "задержка роста;",
                "краниосиностоз.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-2.png", // Или путь к локальному SVG
              heading: "Респираторные:",
              text: [
                "гипоплазия легких;",
                "дыхательная недостаточность;",
                "необходимость кислородной поддержки.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-3.png", // Или путь к локальному SVG
              heading: "Со стороны хрящей и мышц:",
              text: [
                "выраженная мышечная гипотония, вялое сосание;",
                "мембранозный череп.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-4.png", // Или путь к локальному SVG
              heading: "Со стороны пищеварительной системы:",
              text: [
                "рвота;",
                "дегидратация;",
                "лихорадка;",
                "запоры;",
                "слабая прибавка в весе.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-5.png", // Или путь к локальному SVG
              heading: "Нервные:",
              text: ["судороги (витамин В6-зависимые)"],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-6.png", // Или путь к локальному SVG
              heading: "Сердечно-сосудистые:",
              text: [
                "повышение внутричерепного давления;",
                "внутричерепное кровоизлияние;",
                "миелофтизная анемия (вероятно, вследствие уменьшения объема функционирующего костного мозга).",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-7.png", // Или путь к локальному SVG
              heading: "Почечные:",
              text: ["гиперкальциурия;", "нефрокальциноз."],
            },
          ],
        },
        {
          title: "У младенцев",
          content: [
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-1.png", // Или путь к локальному SVG
              heading: "Скелетные симптомы:",
              text: [
                "выраженная гипоминерализация костной ткани;",
                "выраженные скелетные деформации, схожие с таковыми при перинатальной форме;",
                "боли в костях;",
                "краниосиностоз.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-2.png", // Или путь к локальному SVG
              heading: "Респираторные:",
              text: [
                "гипоплазия легких;",
                "дыхательная недостаточность;",
                "необходимость кислородной поддержки.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-3.png", // Или путь к локальному SVG
              heading: "Со стороны хрящей и мышц:",
              text: [
                "мышечная гипотония;",
                "отставание в физическом и моторном развитии;",
                "боли в мышцах.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-5.png", // Или путь к локальному SVG
              heading: "Нервные:",
              text: [
                "повышенная нервно-рефлекторная возбудимость;",
                "витамин В6-зависимые судороги.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-6.png", // Или путь к локальному SVG
              heading: "Сердечно-сосудистые:",
              text: ["повышение внутричерепного давления."],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-7.png", // Или путь к локальному SVG
              heading: "Почечные:",
              text: ["гиперкальциемия;", "нефрокальциноз."],
            },
          ],
        },
        {
          title: "У детей и подростков",
          content: [
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-1.png", // Или путь к локальному SVG
              heading: "Скелетные симптомы:",
              text: [
                "рахитическое поражение конечностей в виде укорочения и деформаций;",
                "повторные переломы;",
                "уменьшение грудной клетки в объеме за счет укорочения ребер;",
                "боли в костях;",
                "краниосиностоз;",
                "задержка начала ходьбы и «утиная» походка.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-3.png", // Или путь к локальному SVG
              heading: "Со стороны хрящей и мышц:",
              text: [
                "скованность движений в суставах;",
                "отставание физического и моторного развития;",
                "задержка начала ходьбы и «утиная» походка.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-4.png", // Или путь к локальному SVG
              heading: "Со стороны пищеварительной системы:",
              text: ["непрогрессирующая миопатия."],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-7.png", // Или путь к локальному SVG
              heading: "Почечные:",
              text: [
                "гиперкальциемия;",
                "нефрокальциноз (реже, чем при инфантильной форме).",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-8.png", // Или путь к локальному SVG
              heading: "Со стороны зубов:",
              text: [
                "преждевременное выпадение временных и постоянных зубов без кровотечения с несформированными или сформированными корнями (иногда с признаками наружной резорбции цемента и дентина корня).",
              ],
            },
          ],
        },
        {
          title: "У взрослых",
          content: [
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-1.png", // Или путь к локальному SVG
              heading: "Скелетные симптомы:",
              text: [
                "псевдопереломы;",
                "многократные плохо заживающие переломы костей (чаще поражаются нижние конечности);",
                "укорочение и деформации длинных трубчатых костей;",
                "походка пациентов нарушена;",
                "снижен рост.",
              ],
            },
            {
              image:
                "https://storage.yandexcloud.net/az-most.ru-storage/media/content/gff/Icon-3.png", // Или путь к локальному SVG
              heading: "Со стороны хрящей и мышц:",
              text: [
                "хондрокальциноз;",
                "псевдоподагра и артропатии с болевым синдромом.",
              ],
            },
          ],
        },
      ],
      selectedItemIndex: null,
    };
  },
  methods: {
    openModal(index) {
      this.selectedItemIndex = index;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.selectedItemIndex = null;
      document.body.style.overflow = "";
    },
  },
};
</script>

<style lang="scss">
.gff__m {
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
    &_item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      border: 1px solid #f4ca2f;
      transition: all 0.2s linear;
      &:hover {
        background-color: #fceecc;
      }
      @media screen and (max-width: 1199px) {
        gap: 0;
        min-height: 175px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: row;
        justify-content: space-between;
        min-height: auto;
        gap: 16px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      &-title {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &-descr {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
      &-svg {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.modal {
  background: #f8f8f8;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  @media screen and (max-width: 1199px) {
    width: 80%;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    overflow: hidden;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4ca2f;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    &_title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  &-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 80vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-block {
    display: flex;
    flex-direction: row;
    gap: 16px;
    &:last-child {
      padding-bottom: 24px;
    }
    &-image {
      flex-shrink: 0;
      width: 82px;
      height: 82px;
      @media screen and (max-width: 767px) {
        width: 56px;
        height: 56px;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__title {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
      }
      ul {
        padding-left: 25px;
        li {
          margin-bottom: 4px;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
