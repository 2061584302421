<template>
  <div class="agus-block-bottom">
    <div class="mb-6" v-for="(block, ind) in blocks" :key="ind">
      <div class="agus-block_block__row">
        <div
          class="agus-block_block__item"
          v-for="(item, iInd) in block.blocks"
          :key="iInd"
        >
          <div class="agus-block_block__item-content">
            <div class="agus-block_block__item-head">
              <div class="agus-block_block__item-icon">
                <img :src="item.icon" alt="svg" />
              </div>
              <div
                class="agus-block_block__item-title"
                v-html="item.title"
              ></div>
            </div>
            <input
              type="checkbox"
              :id="`agus-block_opener_${ind}_${iInd}`"
              v-if="item && item.tip"
            />
            <div class="agus-block_block__item-tip" v-if="item && item.tip">
              <div v-html="item.tip"></div>
            </div>
            <label
              :for="`agus-block_opener_${ind}_${iInd}`"
              v-if="item && item.tip"
              class="agus-block_block__item-button button button_small button_empty-pink d-inline-flex ml-auto"
            >
              <span class="open">Подробнее</span>
              <span class="close">Cкрыть</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 5.5L8.5 10.5L3.5 5.5"
                  stroke="#830051"
                  stroke-linecap="square"
                />
              </svg>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AGUSDropdownOne",
  computed: {
    blocks() {
      return [
        {
          blocks: [
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/1.svg",
              title: "ЦНС (25%)",
              tip: "<ul><li>спутанность сознания;</li><li>энцефалопатия;</li><li>инсульт;</li><li>судороги.</li></ul>",
            },
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/2.svg",
              title: "ССС (33%)",
              tip: "<ul><li>артериальный тромбоз;</li><li>артериальный стеноз;</li><li>артериальная гипертензия;</li><li>кардиомиопатия;</li><li>инфаркт миокарда.</li></ul>",
            },
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/3.svg",
              title: "ЖКТ (47%)",
              tip: "<ul><li>колит;</li><li>боль в животе;</li><li>панкреатит;</li><li>тошнота/рвота;</li><li>гастроэнтерит;</li><li>диарея.</li></ul>",
            },
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/4.svg",
              title: "Дыхательная система (20%)",
              tip: "<ul><li>одышка;</li><li>легочное кровотечение;</li><li>отек легких.</li></ul>",
            },
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/5.svg",
              title: "Мочевыводящая система (49%)",
              tip: "<ul><li>повышение сывороточной концентрации креатинина;</li><li>снижение рСКФ;</li><li>протеинурия.</li></ul>",
            },
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/6.svg",
              title: "Зрение",
              tip: "<ul><li>боль и нечеткое зрение;</li><li>окклюзия центральной вены сетчатки/ венозная застойная ретинопатия;</li><li>кровоизлияние в глаз.</li></ul>",
            },
            {
              icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/agus/icons/7.svg",
              title: "Другие макрососудистые осложнения",
              tip: "<ul><li>атеросклероз периферических артерий;</li><li>гангрена фаланги пальца.</li></ul>",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.agus-block-bottom {
  margin-top: 32px;
  &__title {
    padding: 8px 16px;
    background-color: #8a7098;
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.agus-block_block {
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 72px;
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
    &-icon {
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      img {
      }
    }
    &-arrow {
      width: fit-content;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-content {
      position: relative;
      border: 1px solid #6aa2b9;
      border-radius: 8px;
      padding: 16px;
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 115px;
      height: 100%;
    }

    &-title {
      font-weight: 500;
      @media screen and (max-width: 1199px) {
        max-width: 380px;
      }
    }

    &-tip {
      max-height: 0;
      transition: 0.3s;
      overflow: hidden;
    }

    & input {
      display: none;
    }

    & input {
      &:checked {
        & ~ .agus-block_block {
          &__item {
            &-tip {
              margin-top: 16px;
              max-height: 1500px;
              margin-bottom: 40px;
              @media screen and (max-width: 767px) {
                margin-bottom: 0;
              }
            }
            &-button {
              margin-top: 16px;
              & .open {
                display: none;
              }
              & .close {
                display: block;
              }
              & svg {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
    }

    &-button {
      margin-top: auto;
      position: absolute;
      right: 24px;
      bottom: 20px;
      & .open {
        display: block;
      }
      & .close {
        display: none;
      }
      & svg {
        transform: rotate(0deg);
        transition: 0.3s;
        margin-left: 4px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 16px;
        position: static;
      }
    }

    &:last-child {
      grid-area: 4 / 1 / 5 / 3;
      @media screen and (max-width: 767px) {
        grid-area: auto;
      }
    }
  }
}
</style>
<style lang="scss">
.agus-block_block__item {
  & sup {
    color: inherit !important;
  }
}
</style>
