import { render, staticRenderFns } from "./koselugo-calculator.vue?vue&type=template&id=d72f7be6&scoped=true&"
import script from "./koselugo-calculator.vue?vue&type=script&lang=js&"
export * from "./koselugo-calculator.vue?vue&type=script&lang=js&"
import style0 from "./koselugo-calculator.vue?vue&type=style&index=0&id=d72f7be6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d72f7be6",
  null
  
)

export default component.exports