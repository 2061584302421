<template>
  <div class="calc">
    <div class="calc__title mb-8">
      <div class="mb-4">Формула перевода единиц измерения глюкозы в крови</div>
      <div class="d-flex align-center">
        ммоль/л &nbsp;
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.1883 8L0 8" stroke="white" stroke-miterlimit="10" />
          <path d="M9.81775 1.08813L16.7297 8L9.81775 14.912" stroke="white" />
        </svg>
        &nbsp; мг/дл
      </div>
    </div>
    <div class="calc__block">
      <div class="calc__form-block">
        <div class="calc__form">
          <div class="calc__input-wrap">
            <label for="in1" class="calc__label mb-2">ммоль/л:</label>
            <input id="in1" type="number" v-model="mmol" class="calc__input" />
          </div>
          <div class="calc__arrow">
            <svg
              width="41"
              height="8"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2665 4.35355C40.4618 4.15829 40.4618 3.84171 40.2665 3.64645L37.0845 0.464466C36.8892 0.269204 36.5727 0.269204 36.3774 0.464466C36.1821 0.659728 36.1821 0.976311 36.3774 1.17157L39.2058 4L36.3774 6.82843C36.1821 7.02369 36.1821 7.34027 36.3774 7.53553C36.5727 7.7308 36.8892 7.7308 37.0845 7.53553L40.2665 4.35355ZM0 4.5H39.9129V3.5H0V4.5Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="calc__input-wrap">
            <label for="in2" class="calc__label mb-2">мг/дл:</label>
            <input
              id="in2"
              type="number"
              v-model="convert"
              disabled
              class="calc__input"
            />
          </div>
        </div>
        <div
          class="button calc__button"
          :class="{ button_disabled: !mmol }"
          :disabled="!mmol"
          @click="calculate"
        >
          Преобразовать
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "calcSD2-One",
  data: () => ({
    mmol: null,
    convert: null,
  }),
  methods: {
    calculate() {
      this.convert = (parseInt(this.mmol) * 18.02).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.calc {
  padding: 32px;
  background: #830051;
  @media screen and (max-width: 767px) {
    padding: 24px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffff;
  }

  &__form {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-title {
      margin-right: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1f1f1f;
    }
  }

  &__label {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  &__button {
    margin-top: 24px;
    width: 148px;
    background-color: #ebefee;
    color: #830051;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    &:hover {
      border: 1px solid #ffffff;
      background-color: #830051;
      color: #ffffff;
    }
  }

  &__input {
    width: 148px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    font-family: "Roboto Slab", sans-serif;
    border-radius: 99px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #3c4242;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &__arrow {
    margin: 0 32px;
    @media screen and (max-width: 767px) {
      margin: 0 16px;
    }
  }

  &__form-block {
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
  }

  &__block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
