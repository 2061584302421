var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"soundboard"},[_c('div',{staticClass:"full-screen mb-6"},[_c('div',{staticClass:"full-screen__trigger d-md-none"},[_c('svg',{attrs:{"width":"40","height":"40","viewbox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"40","height":"40","rx":"8","fill":"#830051"}}),_c('path',{attrs:{"d":"M23 11H29V17","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M17 29H11V23","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M29 11L22 18","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M11 29L18 22","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_vm._m(0),_c('div',{staticClass:"soundboard__btn first",on:{"click":function($event){return _vm.soundPlay(
          'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Лихорадка ≥ 38°C.mp3'
        )}}}),_c('div',{staticClass:"soundboard__btn second",on:{"click":function($event){return _vm.soundPlay(
          'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Раздувание крыльев носа.mp3'
        )}}}),_c('div',{staticClass:"soundboard__btn third",on:{"click":function($event){return _vm.soundPlay(
          'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Учащенное дыхание, свистящие хрипы.mp3'
        )}}}),_c('div',{staticClass:"soundboard__btn fourth",on:{"click":function($event){return _vm.soundPlay(
          'https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/audio/Ретракция.mp3'
        )}}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',[_c('source',{attrs:{"srcset":"https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4(mob).jpg","media":"(max-width: 767px)"}}),_c('source',{attrs:{"srcset":"https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4(tab).jpg","media":"(max-width: 1199px)"}}),_c('source',{attrs:{"srcset":"https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4.jpg","media":"(min-width: 1299px)"}}),_c('img',{staticClass:"soundboard__img",attrs:{"src":"https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/4.jpg"}})])}]

export { render, staticRenderFns }