<template>
  <div class="test">
    <div
      class="test-block"
      :class="{ 'test-block_active test-block_active-brown': activeStep > 1 }"
    >
      <div class="test-block__title">
        <div class="test-block__title-text">
          Рекомендации KDIGO по алгоритму диагностики ХБП
        </div>
      </div>
      <div class="test-block__list">
        <div class="d-xl-flex">
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              АГ
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              СД
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              СС3
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              ОПП/госпитализация в анамнезе
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Факторы окружающей среды, или генетические факторы
            </div>
          </div>
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Ожирение
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Пожилой возраст
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Другие коморбидные заболевания с выоским риском
            </div>
          </div>
        </div>
      </div>
      <div
        class="test-button mt-4 button button_brown"
        v-show="activeStep === 1"
        @click="nextStep()"
      >
        Далее
      </div>
    </div>
    <div class="test-block__arrow" v-show="activeStep > 1"></div>
    <div
      class="test-block"
      v-show="activeStep > 1"
      :class="{ 'test-block_active test-block_active-brown': activeStep > 2 }"
    >
      <div class="test-block__title">
        <div class="test-block__title-text">Рассмотреть скрининг ХБП</div>
        <div class="test-block__title-tag">Скрининг</div>
      </div>
      <div class="test-block__list">
        <div class="d-xl-flex">
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Отношение альбумин/креатинин в мочедля обнаружения альбуминурии
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Креатинин сыворотки и цистаин С2 для оценки рСКФ
            </div>
          </div>
        </div>
      </div>
      <div
        class="test-button mt-4 button button_brown"
        v-show="activeStep === 2"
        @click="nextStep()"
      >
        Далее
      </div>
    </div>
    <div class="test-block__arrow" v-show="activeStep > 2"></div>
    <div
      class="test-block"
      v-show="activeStep > 2"
      :class="{ 'test-block_active test-block_active-black': activeStep > 3 }"
    >
      <div class="test-block__title">
        <div class="test-block__title-text">
          Один из следующих факторов проявлется в течении 3 месяцев и более?
        </div>
        <div class="test-block__title-tag">Скрининг</div>
      </div>
      <div class="test-block__list">
        <div class="d-xl-flex">
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              рСКФ &#60;60 мл/мин/1.73 м2
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              ОАКМ ≥ 30 мг/г (3 мг/ммоль)
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Маркеры заболевания почек
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-flex">
        <div
          class="test-button mt-4 button button_brown mr-4"
          v-show="activeStep === 3"
          @click="nextStep(true)"
        >
          Да
        </div>
        <div
          class="test-button mt-4 button button_blue"
          v-show="activeStep === 3"
          @click="nextStep()"
        >
          Нет
        </div>
      </div>
    </div>
    <div class="test-block__arrow" v-show="activeStep > 3"></div>
    <div
      class="test-block"
      v-show="activeStep > 3 && !answer"
      :class="{
        'test-block_active test-block_active-brown-dark': activeStep === 4,
      }"
    >
      <div class="test-block__title">
        <div class="test-block__title-text">Статификация риска</div>
      </div>
      <div class="test-block__list">
        <div class="d-xl-flex">
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Переодическое повторение обследования
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="test-block"
      v-show="activeStep > 3 && answer"
      :class="{ 'test-block_active test-block_active-blue': activeStep > 4 }"
    >
      <div class="test-block__title">
        <div class="test-block__title-text">Статификация риска</div>
      </div>
      <div class="test-block__list">
        <div class="d-xl-flex">
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Классификация/стратификацая риска стадий ХБП
            </div>
          </div>
        </div>
      </div>
      <div
        class="test-button mt-4 button button_brown mr-4"
        v-show="activeStep === 4"
        @click="nextStep()"
      >
        Далее
      </div>
    </div>
    <div class="test-block__arrow" v-show="activeStep > 4"></div>
    <div
      class="test-block"
      v-show="activeStep > 4 && answer"
      :class="{ 'test-block_active test-block_active-blue': activeStep === 5 }"
    >
      <div class="test-block__list">
        <div class="d-xl-flex">
          <div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Определить категорию РСКФ (мл/мин/1.73 м2): С1=≥90, C2 = 60-89;
              C3a = 45-59; C3b = 30-44; C4 = 15-29; C5 = &#60;15
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Идентифицировать и лечить причину ХБП
            </div>
            <div class="test-block__list-item">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1935_71139)">
                  <path
                    d="M14.6663 7.67483V8.28816C14.6655 9.72577 14.2 11.1246 13.3392 12.276C12.4785 13.4275 11.2685 14.2698 9.88991 14.6774C8.51129 15.085 7.03785 15.0361 5.68932 14.5379C4.3408 14.0397 3.18944 13.1189 2.40698 11.9129C1.62452 10.7068 1.25287 9.2802 1.34746 7.8457C1.44205 6.4112 1.99781 5.04571 2.93186 3.95287C3.86591 2.86004 5.1282 2.09841 6.53047 1.78159C7.93274 1.46476 9.39985 1.60972 10.713 2.19483"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6667 2.95474L8 9.62808L6 7.62808"
                    stroke="currentColor"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1935_71139">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.288086)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Определить категорию альбуминурии: A1 (норма или легкое
              повышение): 30 мг/г или &#60;3 мг/ммоль; А2 (умеренное повышение):
              30-300 мг/г или 3-30 мг/моль; А3 (выраженное повышение): &#62;300
              мг/г или &#62;30 мг/ммоль
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HBP-1",
  data: () => ({
    activeStep: 1,
    answer: false,
  }),
  methods: {
    nextStep(answer) {
      this.activeStep++;
      if (answer) {
        this.answer = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  &-block {
    padding: 32px 48px;
    background: #f8f8f8;
    border: 1px solid #b2b4b4;
    border-radius: 24px;
    color: #1f1f1f;
    transition: 0.3s;

    &_active {
      color: #fff;

      & .test-block__title-tag {
        background-color: rgba(235, 239, 238, 0.2);
      }

      &-brown {
        background: #de823c;
        border-color: #de823c;
      }

      &-brown-dark {
        background: #af5c1c;
        border-color: #af5c1c;
      }

      &-black {
        background: #3c4242;
        border-color: #3c4242;
      }

      &-blue {
        background: #2186af;
        border-color: #2186af;
      }
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;

      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        flex-direction: column-reverse;
      }

      &-text {
        margin-right: 15px;
      }

      &-tag {
        margin-left: auto;
        width: fit-content;
        padding: 4px 12px;
        border-radius: 8px;
        background-color: #ebefee;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        @media screen and (max-width: 767px) {
         margin-left: 0;
         margin-bottom: 16px;
        }
      }
    }
    &__list {
      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        svg {
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }

    &__arrow {
      margin: 0 auto;
      display: block;
      content: url("data:image/svg+xml,%3Csvg width='18' height='71' viewBox='0 0 18 71' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='11' y='0.288086' width='4' height='4' rx='2' transform='rotate(90 11 0.288086)' fill='%233C4242'/%3E%3Cpath d='M9 4.28809L9 28.2881' stroke='%23D8DADA' stroke-width='2'/%3E%3Cpath d='M9 28.2881L9 52.2881' stroke='%23D8DADA' stroke-width='2'/%3E%3Crect x='18' y='52.2881' width='18' height='18' rx='9' transform='rotate(90 18 52.2881)' fill='%233C4242'/%3E%3Cpath d='M13.1992 59.4881L9.04437 64.2365C9.02047 64.2638 8.97797 64.2638 8.95406 64.2365L4.79922 59.4881' stroke='white' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  &-button {
    width: 100px;
  }
}

.button {
  &_blue {
    background: #2186af;
    color: #fff;

    &:hover {
      background: #2ca8da;
    }
  }

  &_brown {
    background: #de823c;
    color: #fff;

    &:hover {
      background: #f39045;
    }
  }
}
</style>