<template>
  <div class="enhertu-bottom">
    <div class="mb-6" v-for="(block, ind) in blocks" :key="ind">
      <div class="enhertu-bottom__title" v-html="block.title"></div>
      <div class="enhertu-block__row">
        <div
          class="enhertu-block__item"
          v-for="(item, iInd) in block.blocks"
          :key="iInd"
        >
          <div class="enhertu-block__item-arrow">
            <svg
              width="8"
              height="17"
              viewBox="0 0 8 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.64645 16.3536C3.84171 16.5488 4.15829 16.5488 4.35355 16.3536L7.53553 13.1716C7.7308 12.9763 7.7308 12.6597 7.53553 12.4645C7.34027 12.2692 7.02369 12.2692 6.82843 12.4645L4 15.2929L1.17157 12.4645C0.976311 12.2692 0.659729 12.2692 0.464467 12.4645C0.269204 12.6597 0.269204 12.9763 0.464467 13.1716L3.64645 16.3536ZM3.5 2.06415e-08L3.5 16L4.5 16L4.5 -2.06415e-08L3.5 2.06415e-08Z"
                fill="#3C1053"
              />
            </svg>
          </div>
          <div class="enhertu-block__item-content">
            <div class="enhertu-block__item-title" v-html="item.title"></div>
            <div class="enhertu-block__item-value" v-html="item.value"></div>
            <input
              type="checkbox"
              :id="`opener_${ind}_${iInd}`"
              v-if="item && item.tip"
            />
            <div
              class="enhertu-block__item-tip"
              v-if="item && item.tip"
              v-html="item.tip"
            ></div>
            <label
              :for="`opener_${ind}_${iInd}`"
              v-if="item && item.tip"
              class="
                enhertu-block__item-button
                button button_small button_empty-pink
                d-inline-flex
                ml-auto
              "
            >
              <span class="open">Подробнее</span>
              <span class="close">Cкрыть</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#830051"
                  stroke-linecap="square"
                />
              </svg>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnhertuBottom",
  computed: {
    blocks() {
      return [
        {
          title: "Антитело",
          blocks: [
            {
              title: "T-DM1",
              value: "Трастузумаб",
            },
            {
              title: "ЭНХЕРТУ",
              value: "Трастузумаб*",
            },
          ],
        },
        {
          title: "Химиопрепарат",
          blocks: [
            {
              title: "T-DM1",
              value: "Эмтанзин",
            },
            {
              title: "ЭНХЕРТУ",
              value: "Дерукстекан",
            },
          ],
        },
        {
          title: "Механизм действия химиопрепарата",
          blocks: [
            {
              title: "T-DM1",
              value:
                "Ингибитор микротрубочек к которому у большинства пациентов развивается вторичная резистентность<sup>5-7</sup>",
            },
            {
              title: "ЭНХЕРТУ",
              value: "Ингибитор топоизомеразы I",
            },
          ],
        },
        {
          title: "Соотношение химиопрепарата и антител",
          blocks: [
            {
              title: "T-DM1",
              value:
                "Неоднородное ~3,5 (ухудшение ФК и усиление токсичности)<sup>1, 8-9</sup>",
            },
            {
              title: "ЭНХЕРТУ",
              value: "Высокое ~8:1",
            },
          ],
        },
        {
          title: "Селективное расщепление линкера в опухоли",
          blocks: [
            {
              title: "T-DM1",
              value: "Нет",
              tip: "Нестабильность линкера приводит к увеличению концентрации химиопрепарата в плазме крови с развитием нецелевой токсичности<sup>1,7</sup>",
            },
            {
              title: "ЭНХЕРТУ",
              value: "Да",
              tip: "Запатентованная тетрапептидная структура линкера обеспечивает селективное расщепление линкера лизосомальными ферментами, активность которых повышена в опухолевых клетках<sup>1,10</sup>",
            },
          ],
        },
        {
          title: "Селективное расщепление линкера в опухоли",
          blocks: [
            {
              title: "T-DM1",
              value: "Нет",
              tip: "Химиопрепарат не обладает мембранной проницаемостью, что исключает цитотоксическое воздействие на соседние опухолевые клетки<sup>1</sup>",
            },
            {
              title: "ЭНХЕРТУ",
              value: "Да",
              tip: "Препарат обладает высокой мембранной проницаемостью, что позволяет уничтожать как опухолевые клетки-мишени, так и окружающие их опухолевые клетки<sup>1,4</sup>",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.enhertu-bottom {
  margin-top: 32px;
  &__title {
    padding: 8px 16px;
    background-color: #8a7098;
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.enhertu-block {
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;

     @media screen and (max-width: 767px) {
      padding: 16px 0 0;
      grid-template-columns: 1fr;
      row-gap: 16px;
     }
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-arrow {
      width: fit-content;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-content {
      border: 1px solid #fff;
      padding: 16px;
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-title {
      margin-bottom: 4px;
    }

    &-value {
      margin-bottom: 4px;
    }

    &-tip {
      max-height: 0;
      transition: 0.3s;
      overflow: hidden;
    }

    & input {
      display: none;
    }

    & input {
      &:checked {
        & ~ .enhertu-block {
          &__item {
            &-tip {
              max-height: 500px;
              margin-bottom: 10px;
            }
            &-button {
              & .open {
                display: none;
              }
              & .close {
                display: block;
              }
              & svg {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }

    &-button {
      margin-top: auto;
      & .open {
        display: block;
      }
      & .close {
        display: none;
      }
      & svg {
        transform: rotate(0deg);
        transition: 0.3s;
        margin-left: 4px;
      }
    }

    &:first-child {
      .enhertu-block {
        &__item {
          &-content {
            border-color: #b19fba;
          }
        }
      }
    }

    &:last-child {
      .enhertu-block {
        &__item {
          &-content {
            border-color: #de823c;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.enhertu-block__item {
  & sup {
    color: inherit !important;
  }
}
</style>